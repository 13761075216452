import React from 'react';
import { IconLoader } from '../../assets/icons/IconLoader';

interface PlaylistDropZoneLoadingProps {};

const PlaylistDropZoneLoading: React.FC<PlaylistDropZoneLoadingProps> = (props: PlaylistDropZoneLoadingProps) => {
    
    return(
        <div className="drop-here-area" >
            <div style={{width: '40px'}}>
                <IconLoader class='stroke-gray-light' />
            </div>
        </div>
    );

};

export default React.memo(PlaylistDropZoneLoading);
