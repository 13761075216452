import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DashboardTitle,
    DashboardWrapper,
    DashboardItem,
    DashboardScreenItem,
    DashboardLogItem,
} from '../../components';
import DashboardInfo from "../../models/DashboardInfo";
import Screen from "../../models/Screen";
import DashboardService from "../../services/use_case/dashboard/DashboardService";
import DashboardResponse from "../../services/use_case/dashboard/DashboardResponse";
import { AxiosResponse } from 'axios';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';

interface DashboardProps {};

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {

    const [t] = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [info, setInfo] = useState<DashboardInfo | null>(null);

    let dashboardService: DashboardService = new DashboardService();

    useEffect(() => {
        _fetchData();

        return () => {
            dashboardService.cancelRequest();
        }

    }, []);

    const _fetchData = () => {
        setIsLoading(true);

        dashboardService.fetch().then( (response: AxiosResponse<DashboardResponse>) => {
            setInfo(response.data.data);
            setIsLoading(false);
        }).catch((error: any) => {
            setIsLoading(false);
        });
    }

    const _renderDashboardCards = () => {

        if(isLoading || info === null) return null;

        let cards: JSX.Element[] = [
            <div key={1} className="col-12 col-md-4 mt-2 same-height same-height--full">
                <DashboardItem
                    title={t('Users')}
                    subtitle={t('Active users')}
                    value={`${info?.total_active_users}`}
                />
            </div>,
            <div key={2} className="col-12 col-md-4 mt-2 same-height same-height--full">
                <DashboardItem 
                    title={t('Screens')}
                    subtitle={t('Active screen')}
                    value={`${info?.total_screens}`}
                />
            </div>,
            <div key={3} className="col-12 col-md-4 mt-2 same-height same-height--full">
                <DashboardItem
                    title={t('Schedule')}
                    subtitle={t('Active schedule')}
                    value={`${info?.total_schedules}`}
                />
            </div>
        ];

        return(
            <div className="row">
                {cards}
            </div>
        );

    }

    const _renderScreens = () => {

        if(isLoading || info === null) return null;

        const screenSize: number = info.screens.length;

        if(screenSize === 0) return;

        const screens = info.screens.map((screen: Screen) => {
            return(
                <div 
                    key={screen.id}
                    className="col-sm-4 col-lg-2 same-height mb-3"
                >
                    <DashboardScreenItem
                        screen={screen}
                    />
                </div>
            );
        }) ?? [];

        return(
            <>

                <div className="my-3">
                    <DashboardTitle
                        title={t('Screens')}
                    />
                </div>

                <div className="row">
                    {screens}
                </div>

            </>
        );

    }

    const _renderInfo = () => {

        if(isLoading || info === null) return null;

        return(
            <>
                
                <div className="my-3">
                    <DashboardTitle
                        title={t('Dashboard Log')}
                    />
                </div>

                <div className="row">

                    <div className="col-sm-12 col-xl-6 same-height mb-3">

                        {_renderLog()}

                    </div>

                    <div className="col-sm-12 col-xl-6 same-height mb-3">
                        
                        {_renderMap()}

                    </div>

                </div>

            </>
        );

    }

    const _renderLog = () => {

        return(
            <>
                <div className="row">

                    <div className="col-sm-12 mb-3">
                        <DashboardLogItem
                            name="Acceso"
                            email="hp@soporte.com"
                            date="29-01-2021 08:30"
                            type="info"
                        />
                    </div>

                    <div className="col-sm-12 mb-3">
                        <DashboardLogItem
                            name="Acceso"
                            email="hp@soporte.com"
                            date="29-01-2021 08:30"
                            type="info"
                        />
                    </div>

                    <div className="col-sm-12 mb-3">
                        <DashboardLogItem
                            name="Acceso"
                            email="hp@soporte.com"
                            date="29-01-2021 08:30"
                            type="info"
                        />
                    </div>

                    <div className="col-sm-12 mb-3">
                        <DashboardLogItem
                            name="Acceso"
                            email="hp@soporte.com"
                            date="29-01-2021 08:30"
                            type="info"
                        />
                    </div>

                    <div className="col-sm-12">
                        <DashboardLogItem
                            name="Acceso"
                            email="hp@soporte.com"
                            date="29-01-2021 08:30"
                            type="info"
                        />
                    </div>

                </div>
            </>
        );

    }

    const _renderScreenMarkers = () => {

        if(isLoading || info === null) return null;

        const screenSize: number = info.screens.length;

        if(screenSize === 0) return;

        const origin: number[] = [39.54821935, -0.4583236];

        return info.screens.map((screen: Screen, index: number) => {

            const lat: number = origin[0] + Math.pow(10, index - 1);
            const lng: number = origin[1] + Math.pow(10, index - 1);

            return(
                <Marker
                    key={screen.id}
                    position={[lat, lng]}
                    icon={new Icon({ iconUrl: "/img/hp-marker.png", iconSize: [25, 25] })}
                >
                    <Popup>{screen.name ?? ""}</Popup>
                </Marker>
            );
        });

    }

    const _renderMap = () => {

        return(
            <>
                    
                    <MapContainer 
                        className="map"
                        center={[39.54821935, -0.4583236]}
                        zoom={15}
                        scrollWheelZoom={true}
                        style={{ width: '100%', height: '100%', }}
                    >
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker 
                            position={[39.54821935, -0.4583236]}
                            icon={new Icon({ iconUrl: "/img/marker.svg", iconSize: [25, 25] })}
                        >
                            <Popup>
                                Cactus DS
                            </Popup>
                        </Marker>

                        {_renderScreenMarkers()}

                    </MapContainer>

            </>
        );

    }

    return(
        <DashboardWrapper>
            
            <DashboardTitle
                title={t('Dashboard')}
                isLoading={isLoading}
                withRefreshIcon={!isLoading}
                refreshIconCallBack={() => {
                    _fetchData();
                }}
            />

            {_renderDashboardCards()}

            {_renderScreens()}

            {_renderInfo()}

            <br />

        </DashboardWrapper>
    );

};

export default React.memo(Dashboard);
