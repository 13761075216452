import React from 'react';
import { Paginate } from "../../services/PaginateResponse";

interface PaginationProps {
    paginate: Paginate<any> | null;
    onChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {

    const _changePage = (page: number) => {
        props.onChange(page);
    }

    const _renderItem = () => {
        
        if(!props.paginate || props.paginate.total === 0) return null;

        let currentPage: number = props.paginate.current_page;

        let liElements = [];

        if(currentPage !== 1) {
            liElements.push(
                <li key={`paginate_0`} className="pagination__item" onClick={() => _changePage(currentPage - 1)}>
                    <a href="!#" className="pagination__link">
                        {'<'}
                    </a>
                </li>
            );
        }

        for(let i = 1; i <= props.paginate.last_page; i++) {
            let classNameString = "pagination__link";
            if(i === props.paginate.current_page) classNameString += " pagination__link--active";
            liElements.push(
                <li key={`paginate_${i}`} className="pagination__item" onClick={() => _changePage(i)}>
                    <a href="!#" className={classNameString}>
                        {i}
                    </a>
                </li>
            );
        }

        if(props.paginate.current_page !== props.paginate.last_page) {
            liElements.push(
                <li key={`paginate_${props.paginate.last_page + 1}`} className="pagination__item" onClick={() => _changePage(currentPage + 1)}>
                    <a href="!#" className="pagination__link">
                        {'>'}
                    </a>
                </li>
            );
        }

        if(liElements.length <= 2) return null;

        return liElements;

    }

    return(
        <ul className="pagination">
            {_renderItem()}
        </ul>
    );

};

export default React.memo(Pagination);
