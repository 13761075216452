import { AxiosResponse } from "axios";
import CancelRequestError from "../services/CancelRequestError";
import WidgetService from "../services/use_case/widget/WidgetService";
import ApiResponse from "../services/ApiResponse";
import PaginateResponse from "../services/PaginateResponse";
import ActionType from "./Action";
import * as actionTypes from './types';
import Widget, { WidgetFilter } from "../models/Widget";

let widgetService: WidgetService;

/*
 * WIDGET
 */
export const widgetFetch = (page: number = 1, search: string = "", filter: string = WidgetFilter.all) => {

    return async (dispatch: any, getState: any) => {

        dispatch( widgetCancelRequest() );
        if(page === 1) dispatch( widgetChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<PaginateResponse<Widget>> = await widgetService.fetch(page, search, filter);
            let widgetResponse: PaginateResponse<Widget> = response.data;
            if(page === 1) dispatch( widgetFetchResponse(widgetResponse) );
            else dispatch( widgetFetchNextResponse(widgetResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( widgetChangeValue('isLoading', false) );
        }

    };

}

export const widgetFetchResponse = (payload: PaginateResponse<Widget>): ActionType<PaginateResponse<Widget>> => {
    return {
        type: actionTypes.WIDGET_FETCH,
        payload,
    }
}

export const widgetFetchNextResponse = (payload: PaginateResponse<Widget>): ActionType<PaginateResponse<Widget>> => {
    return {
        type: actionTypes.WIDGET_FETCH_NEXT,
        payload,
    }
}

export const widgetCreate = (widget: Widget, callback: () => void) => {

    return async (dispatch: any, getState: any) => {
    
        try {
            dispatch( widgetChangeValue('isLoading', true) );

            const response: AxiosResponse<ApiResponse<Widget>> = await widgetService.store({
                name: widget.name,
                // description: widget.description,
                mime_type: widget.mime_type,
                path: widget.path,
            });
            const widgetResponse: ApiResponse<Widget> = response.data;
            dispatch( widgetCreateResponse(widgetResponse.data!) );
            dispatch( widgetChangeValue('isLoading', false) );
            callback();
        }
        catch(error) {
            dispatch( widgetChangeValue('isLoading', false) );
        }

    };

}

export const widgetCreateResponse = (widget: Widget) => {
    return {
        type: actionTypes.WIDGET_CREATE,
        payload: widget,
    }
}

export const widgetUpdate = (widget: Widget) => {
    return {
        type: actionTypes.WIDGET_UPDATE,
        payload: widget,
    }
}

export const widgetCancelRequest = () => {

    return async () => {
        widgetService?.cancelRequest();
        widgetService = new WidgetService();
    };

}

export const widgetChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.WIDGET_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}

export const widgetDelete = (widgets: Widget[]) => {

    return async (dispatch: any, getState: any) => {
        
        dispatch( widgetChangeValue('isLoading', true) );

        if(!widgetService) widgetService = new WidgetService();

        for(let i = 0; i < widgets.length; i++) {

            try {
                await widgetService.delete(widgets[i].id);
            }
            catch {}

        }

        dispatch( widgetChangeValue('isLoading', false) );
        dispatch( widgetFetch() );

    }

}
