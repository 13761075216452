import User from "./User";
import Option from "./Option";

export type OrganizationChartData = {
    id: String;
    children: OrganizationChartData[];
    data: Hierarchy;
}

export function getHierarchyOption(hierarchy: Hierarchy): Option { 
    return {
        value: hierarchy.id,
        label: `${hierarchy.name ?? ""} ${hierarchy.last_name ?? ""}`,
    }
}

export function getSubordinate(hierarchy: Hierarchy): Hierarchy[] {

    if(hierarchy.nested_children.length === 0) {
        return [
            hierarchy,
        ];
    }

    const hierarchys: Hierarchy[] = hierarchy.nested_children.reduce((accumulator: Hierarchy[], currentValue: Hierarchy) => {
        return accumulator.concat(currentValue, getSubordinate(currentValue) );
    }, []);

    const data: Set<Hierarchy> = new Set(hierarchys);

    return Array.from(data);
}

// MARK: - Recursive function
function getOrganizationChartchildrenData(hierarchy: Hierarchy): OrganizationChartData {

    if(hierarchy.nested_children.length === 0) {
        return {
            id: hierarchy.id,
            children: [],
            data: hierarchy,
        }
    }

    return {
        id: hierarchy.id,
        children: hierarchy.nested_children.map( (data: Hierarchy) => {
            return getOrganizationChartchildrenData(data);
        }),
        data: hierarchy,
    };
}
// MARK: - End Recursive function

export function getOrganizationChartData(hierarchy: Hierarchy | null): OrganizationChartData | null {

    if(!hierarchy) return null;
    
    return {
        id: hierarchy.id,
        children: hierarchy.nested_children.map( (data: Hierarchy) => {
            return getOrganizationChartchildrenData(data);
        }),
        data: hierarchy,
    };

}

export default interface Hierarchy extends User {
    nested_children: Hierarchy[];
};
