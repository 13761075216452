import * as actionTypes from '../actions/types';
import Hierarchy from '../models/Hierarchy';


export interface HierarchyReducerState {
    readonly data: Hierarchy | null;
    readonly message: string | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: HierarchyReducerState = {
    data: null,
    message: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.HIERARCHY_FETCH:
            
            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message,
                isLoading: false,
            }

        case actionTypes.HIERARCHY_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
