import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsideMenu, NavHeader } from '..';
import * as actionCreator from "../../actions";

interface DashboardWrapperProps {
    children?: React.ReactNode;
    rightColumn?: boolean;
};

const DashboardWrapper: React.FC<DashboardWrapperProps> = (props: DashboardWrapperProps) => {

    const dispatch = useDispatch();
    const headerSubmenuReducer = useSelector( (store: any) => store.headerSubmenuReducer );

    useEffect(() => {
        window.addEventListener('click', _handleSubmenuHeader)
        return () => {
            window.removeEventListener('click', _handleSubmenuHeader)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _handleSubmenuHeader = (e:any) => {
        
        if(!headerSubmenuReducer.status) return;

        let container = document.querySelector('.navbar__user');
        if (!container?.contains(e.target)) {
            dispatch( actionCreator.headerSubmenuChangeValue( false ) ) 
        }
    }

    return(
        <>
            <NavHeader />
            <AsideMenu />
            <div id="content" className={`${props.rightColumn ? 'withRightColumn' : ''}`}>
                { props.children }
            </div>
        </>
    );

};

export default React.memo(DashboardWrapper);
