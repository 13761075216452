import * as actionTypes from '../actions/types';
import PaginateResponse from '../services/PaginateResponse';

export interface ScreenReducerState {
    readonly data: PaginateResponse<Screen[]> | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: ScreenReducerState = {
    data: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.SCREEN_FETCH:
            
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            }

        case actionTypes.SCREEN_FETCH_NEXT:

            let actualScreens: Screen[] = state?.data?.data ?? [];
            let payload: PaginateResponse<Screen[]> = action.payload.data;
            let newScreens: any = payload.data ?? [];

            if(action.payload.current_page <= state?.data?.current_page) {
                return {
                    ...state,
                }
            }

            newScreens = actualScreens.concat( newScreens );
            payload.data = newScreens;

            return {
                ...state,
                data: payload,
                isLoading: false,
            }

        case actionTypes.SCREEN_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
