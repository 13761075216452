import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import CancelRequestError from "./CancelRequestError";
import TimeoutRequestError from "./TimeoutRequestError";
import { Environment, EnvironmentS3, EnvironmentTimeService } from "./Environment";
import iziToast from 'izitoast';

import { store } from '../store';
import * as actionCreators from '../actions';
import Axios, { CancelTokenSource } from "axios";

export default class BaseService {

    public readonly baseURL: string;
    protected readonly prefix: string = "api";
    protected readonly version: string = "v1";
    protected readonly cactusS3BaseURL: string;
    protected readonly cactusTimeServiceBaseURL: string;
    public token: string | null;
    protected axios!: AxiosInstance;
    private readonly timeout: number = 20000;

    private readonly source: CancelTokenSource;

    constructor() {

        this.source = Axios.CancelToken.source();
        
        if(process.env.NODE_ENV === "production") {
            this.baseURL = `${Environment.production}/${this.prefix}/${this.version}`;
            this.cactusS3BaseURL = EnvironmentS3.production;
            this.cactusTimeServiceBaseURL = EnvironmentTimeService.production;
        }
        else if(process.env.NODE_ENV === "test") {
            this.baseURL = `${Environment.staging}/${this.prefix}/${this.version}`;
            this.cactusS3BaseURL = EnvironmentS3.staging;
            this.cactusTimeServiceBaseURL = EnvironmentTimeService.staging;
        }
        else {
            this.baseURL = `${Environment.develop}/${this.prefix}/${this.version}`;
            this.cactusS3BaseURL = EnvironmentS3.develop;
            this.cactusTimeServiceBaseURL = EnvironmentTimeService.develop;
        }

        const { authReducer: { token } } = store.getState();
        this.token = token;
        
        this.init();
        this.errorHandling();
    }

    public cancelRequest() {
        this.source.cancel();
    }

    public getCancelTokenStructure() {
        return {
            cancelToken: this.source.token
        };
    }

    private init() {

        let lang = localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en";

        let options: AxiosRequestConfig = {
            baseURL: this.baseURL,
            timeout: process.env.NODE_ENV === "production" ? this.timeout : this.timeout * 3,
            headers: {
                "Accept-Language": lang,
                'Access-Control-Allow-Origin': '*',
            }
        }

        if(this.token) {
            options.headers = {
                ...options.headers,
                'Authorization': `Bearer ${this.token}`,
            }
        }

        this.axios = axios.create(options);

    }

    private errorHandling() {
        
        let regexLogin: RegExp = /login$/;
        
        this.axios.interceptors.response.use((response: AxiosResponse<any>) => {

            if(response.data.message) {
                const responseUrl: string = response.config.url || ''
                if(!regexLogin.test(responseUrl))
                this.showSuccessMessage(response.data.message)
            }
            return response

        }, (error: any) => {

            if(
                Axios.isCancel(error) ||
                error.response?.status === null || 
                error.response?.status === undefined
            ) {

                if(error.response) return Promise.reject(error.response);
                else if(error.message?.toLowerCase().includes("timeout")) return Promise.reject( new TimeoutRequestError() );
                return Promise.reject( new CancelRequestError() );
            }

            switch(error.response.status) {
                case 401:
                    
                    const url: string = error.response.config.url;

                    if(regexLogin.test(url)) this.showErrorToast(error);
                    else store.dispatch<any>( actionCreators.authLogout() );

                    break;
                case 403:
                case 404:
                case 422:
                    this.showErrorToast(error);
                    break;
                default:
                    break;
            }

            return Promise.reject(error.response);

        });

    }

    private showErrorToast(error: any) {
        let data = error.response.data;
        let message: string = data.message ?? "";
        let errors: Array<String> = [];

        for(let key in data.errors) {
            let value = data.errors[key];
            Array.isArray(value) ? errors.push(value[0]) : errors.push(value);
        }

        if(errors.length) message = errors[0].toString();

        iziToast.error({
            title: '',
            message,
            position: 'bottomRight',
            color: 'cactus--red'
        });
    }

    private showSuccessMessage(message: string) {

        iziToast.info({
            title: 'Info',
            message,
            position: 'bottomRight',
            color: 'cactus--green'
        });
        
    }
    
}
