import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreator from "../../actions";

import { 
    CactusLabelInput, 
    DashboardTitle, 
    DashboardWrapper 
} from '../../components';

import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import UserService from '../../services/use_case/user/UserService';

export type UserInputs = {
    name: string;
}

interface ProfileProps {};

const Profile: React.FC<ProfileProps> = (props: ProfileProps) => {

    const [t] = useTranslation();
    const dispath = useDispatch();

    const { user } = useSelector( (store: any) => store.authReducer )

    const { handleSubmit, register, errors } = useForm<UserInputs>({
        defaultValues: {
            ...user
        }
    });

    const [isLoading, setisLoading] = useState(false)

    const textValidator = register(useFormValidatorText());

    const _handleOnSubmit = async(data: UserInputs) => {
        setisLoading(true);
        try {

            await new UserService().update({
                ...user,
                ...data,
            });
            dispath( actionCreator.authMe() );
            setisLoading(false);
            
        } catch (err){
            setisLoading(false);
        }
    }
    
    const _renderBtnSubmit = () => {
        return (
            <button 
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={isLoading ?? false}
            >
                {t('Update_profile')}
            </button>
        );
    }
    
    return(
        <DashboardWrapper>
            <DashboardTitle title={t('Profile')} isLoading={isLoading}/>
            
            <div className="row">
                <div className="col-12 col-md-6 same-height">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(_handleOnSubmit)}>
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <CactusLabelInput
                                            labelTitle={t('Name')}
                                            inputAttributes={{
                                                id: "name",
                                                name: "name",
                                                type:  "name",
                                                // value: user.name,
                                                placeholder: t('Name'),
                                                autoComplete: "name",
                                                ref: textValidator,
                                            }}
                                            errorMessage={errors.name ? errors.name.message : null}
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 mt-lg-0 mt-3">
                                        <CactusLabelInput
                                            labelTitle={t('Email')}
                                            inputAttributes={{
                                                name: "email",
                                                disabled: true,
                                                classprops: 'disabled',
                                                ref: register
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 text-right">
                                        {_renderBtnSubmit()}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>    
                
            </div>  
        </DashboardWrapper>
    );

};

export default React.memo(Profile);
