import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface MenuItemProps {
    icon: string;
    text: string;
    link: string;
};

const MenuItem: React.FC<MenuItemProps> = (props: MenuItemProps) => {

    const [t] = useTranslation();

    return(
        <>
            <li>
                <NavLink to={props.link} activeClassName="active">
                    <i className={props.icon}></i>
                    <span>{t(props.text)}</span>
                </NavLink>
            </li>
        </>
    );

};

export default React.memo(MenuItem);
