import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default () => (
    <SkeletonTheme color={process.env.REACT_APP_SKELETON_COLOR} highlightColor={process.env.REACT_APP_SKELETON_HIHGLIGHTS_COLOR}>
        <div className="media-item card">
            <Skeleton className="col-12" height={150} />
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <Skeleton count={3} />
                    </div>
                </div>
            </div>
        </div>
    </SkeletonTheme>
);
