import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import { CustomSelect } from "../../components";
import Option from "../../models/Option";

import Hierarchy from "../../models/Hierarchy";
import { CactusLabelInput } from "../../components";
import useFormValidatorEmail from '../../hooks/validators/use-form-validator-email';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';

export type HierarchyFormInputs = {
    user_id?: string | null;
    name: string;
    last_name?: string | null;
    email: string;
    password: string;
    passwordRepeat: string;
}

interface HierarchyFormProps {
    data: Hierarchy | null;
    subordinates: Option[];
    onSubmit: (data: HierarchyFormInputs) => void;
    btnLoading?: boolean;
};

const HierarchyForm: React.FC<HierarchyFormProps> = (props: HierarchyFormProps) => {

    const [t] = useTranslation();
    const [value, setValue] = useState<Option | null>(null);

    const userHierarchy: Hierarchy | null = props.data;
    const isEditing: boolean = !!userHierarchy;

    const options: Option[] = props.subordinates.filter((option: Option) => {
        return userHierarchy?.id !== option.value;
    });

    const { handleSubmit, register, errors, getValues, reset } = useForm<HierarchyFormInputs>();

    const _handleOnSubmit = (data: HierarchyFormInputs) => {
        props.onSubmit({
            ...data,
            user_id: value?.value ?? null,
        });
    }

    const requiredRegister = register(useFormValidatorText());
    const emailRegister = register(useFormValidatorEmail());
    const passwordRegister = register(useFormValidatorText());

    useEffect(() => {
        reset({...userHierarchy});

        if(userHierarchy) {
            
            if(options.length === 0) {
                setValue(null);
                return;
            }

            const value: Option | null = options.find((option: Option) => option.value === userHierarchy.user_id) ?? null;

            setValue(value);

        }

    }, [userHierarchy]);

    const _renderBtnSubmit = () => {
        return (
            <button 
                data-testid="submit"
                type="submit"
                className="btn btn-cactus mt-2"
                disabled={props.btnLoading ?? false}
            >
                {isEditing ? t('Update') : t('Create')}
            </button>
        );
    }

    const _renderPassword = () => {

        if(isEditing) return;

        return(
            <>
                
                <div className="col-12">
                    <CactusLabelInput
                        labelTitle={t('Password')}
                        inputAttributes={{
                            "data-testid": "password",
                            id: "password",
                            name: "password",
                            type:  "password",
                            placeholder: "*****",
                            autoComplete: "new-password",
                            ref: passwordRegister,
                        }}
                        errorMessage={errors.password ? errors.password.message : null}
                    />
                </div>

                <div className="col-12">
                    <CactusLabelInput
                        labelTitle={t('Password')}
                        inputAttributes={{
                            "data-testid": "passwordRepeat",
                            id: "passwordRepeat",
                            name: "passwordRepeat",
                            type:  "passwordRepeat",
                            placeholder: "*****",
                            autoComplete: "new-password",
                            ref: passwordRegister,
                        }}
                        errorMessage={errors.passwordRepeat ? errors.passwordRepeat.message : null}
                    />
                </div>

            </>
        );

    }

    const _renderParent = () => {

        if(options.length === 0) return null;

        return(
            <div className="col-12 mb-3">
                <span className="form__label mb-2">{t('Direct Parent')}</span>
                <CustomSelect
                    options={options}
                    value={value}
                    widthDefaultOption={false}
                    onChange={(selectedOption: Option) => {
                        setValue(selectedOption);
                    }}
                />
            </div>
        );

    }

    return(
        <form onSubmit={handleSubmit(_handleOnSubmit)}>

            <div className="row">

                <div className="col-12">
                    <CactusLabelInput
                        labelTitle={t('Name')}
                        inputAttributes={{
                            "data-testid": "name",
                            id: "name",
                            name: "name",
                            type:  "text",
                            placeholder: "",
                            ref: requiredRegister,
                        }}
                        errorMessage={errors.name ? errors.name.message : null}
                    />
                </div>

                <div className="col-12">
                    <CactusLabelInput
                        labelTitle={t('Last Name')}
                        inputAttributes={{
                            "data-testid": "last_name",
                            id: "last_name",
                            name: "last_name",
                            type:  "text",
                            placeholder: "",
                            ref: register(),
                        }}
                    />
                </div>

                {_renderParent()}
                
                <div className="col-12">
                    <CactusLabelInput
                        labelTitle={t('Email')}
                        inputAttributes={{
                            "data-testid": "email",
                            id: "email",
                            name: "email",
                            type:  "email",
                            placeholder: "*****@email.com",
                            autoComplete: "email",
                            ref: emailRegister,
                            disabled: !!isEditing,
                        }}
                        errorMessage={errors.email ? errors.email.message : null}
                    />
                </div>

                {_renderPassword()}

                <div className="col-12">
                    {_renderBtnSubmit()}
                </div>

            </div>

        </form>
    );

};

export default React.memo(HierarchyForm);
