import React from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { PlaylistMediaItemTypes } from '../../containers/PlaylistDetail/PlaylistDetail';

interface PlaylistDropZoneEmptyProps {};

const PlaylistDropZoneEmpty: React.FC<PlaylistDropZoneEmptyProps> = (props: PlaylistDropZoneEmptyProps) => {

    const [t] = useTranslation();

    const [ { isOver } , drop] = useDrop({
        accept: PlaylistMediaItemTypes.CARD,
        // drop: (item, monitor) => {
        //     console.log('drop', item, monitor);
        //     console.log('monitor.getDropResult()', monitor.getDropResult());
        //     console.log('item.getDropResult()', item.type);
        // },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        })
        
    });

    return(
        <div 
        ref={drop}
        style={
            {
                borderColor:  isOver ? '#9f5d27': '#656465',
            }
        }
        className="playlist-media-drop-area mt-4">
                { t('Drag_and_drop_media_items_here') }
        </div>
    );

};

export default React.memo(PlaylistDropZoneEmpty);
