import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Widget, { WidgetSelection } from "../../models/Widget";

import ApiResponse from "../../services/ApiResponse";
import WidgetLayoutResponse, { WidgetLayout } from "../../services/use_case/widget/WidgetLayoutResponse";
import WidgetService from "../../services/use_case/widget/WidgetService";

import {
    CactusLabelInput,
} from "../index";

import {
    IconLoader,
} from "../../assets/icons/IconLoader";

interface FormWidgetTimeProps {
    widgetSelected: WidgetSelection | null;
    disableSubmitButton?: boolean;
    createWidget?: (widget: Widget) => void;
};

const FormWidgetTime: React.FC<FormWidgetTimeProps> = (props: FormWidgetTimeProps) => {

    const [t] = useTranslation();

    const widgetService: WidgetService = new WidgetService();

    const [isLoading, setIsloading] = useState<boolean>(false);
    const [allLayout, setAllLayout] = useState<WidgetLayout[]>([]);
    const [layout, setLayout] = useState<string>("default");
    const [name, setName] = useState<string>("");
    const [city, setCity] = useState<string>("");

    useEffect(() => {

        _retrieveLayouts();

        return () => {
            setName("");
            setCity("");
        }

    }, []);

    const _retrieveLayouts = async () => {

        try {
            setIsloading(true);

            const response: ApiResponse<WidgetLayoutResponse> = await widgetService.fethLayouts();
            const widgetLayoutResponse: WidgetLayoutResponse = response.data!;
            const widgetLayouts: WidgetLayout[] = widgetLayoutResponse.data?.layouts ?? [];
            console.warn("response", widgetLayouts)

            setAllLayout(widgetLayouts);
            setIsloading(false);
        }
        catch(error) {
            setIsloading(false);
        }

    }

    const _renderLayouts = () => {

        return allLayout.map((widgetLayout: WidgetLayout, index: number) => {

            const isActive: boolean = widgetLayout.name === layout;
            const border: string = isActive ? "1px dashed #c0712f" : "none";

            return(
                <div 
                    className="col-sm-4 pointer"
                    key={index}
                    onClick={() => setLayout(widgetLayout.name)}
                >
                    <img 
                        src={widgetLayout.image}
                        alt={widgetLayout.name}
                        style={{
                            border,
                        }}
                    />
                </div>
            );
        })

    }

    if(isLoading) {
        return(
            <div key="loading" className="d-flex justify-content-center">
                <IconLoader class='stroke-gray-light' styles={{ width: '50px', height: '50px' }} />
            </div>
        );
    }

    return(
        <form
            onSubmit={(e) => {
                e.preventDefault();

                const path: string = `${layout ?? ''}?city=${city}`;
                
                const newWidget: Widget = {
                    id: "",
                    name,
                    user_id: "",
                    original_name: "",
                    mime_type: props.widgetSelected?.mime_type ?? "",
                    path,
                    full_path: "",
                    file_duration: 0,
                    description: "",
                    updated_at: "",
                    created_at: "",
                }

                props.createWidget?.(newWidget);
            }}
        >
            
            <CactusLabelInput
                labelTitle={t('Name')}
                inputAttributes={{
                    name: "name",
                    type:  "text",
                    placeholder: t('Name'),
                    required: true,
                    value: name,
                    onChange: (event: any) => { setName(event.target.value) },
                }}
            />

            <CactusLabelInput
                labelTitle={t('City')}
                inputAttributes={{
                    name: "city",
                    type:  "text",
                    placeholder: t('City'),
                    required: true,
                    value: city,
                    onChange: (event: any) => { setCity(event.target.value) },
                }}
            />

            <label className="w-100">
                <span className="form__label mb-2">{t('Layout')}</span>

                <div className="row">
                    {_renderLayouts()}
                </div>

            </label>

            <button
                type="submit"
                className="btn btn-cactus mt-3"
                disabled={props.disableSubmitButton}
            >
                {t('Create')}
            </button>
        </form>
    );

};

export default React.memo(FormWidgetTime);
