import { AxiosResponse } from "axios";
import Playlist from "../models/Playlist";
import CancelRequestError from "../services/CancelRequestError";
import PlaylistResponse from "../services/use_case/playlist/PlaylistResponse";
import PlaylistService from "../services/use_case/playlist/PlaylistService";
import ActionType from "./Action";
import * as actionTypes from './types';

let playlistService: PlaylistService;

/*
 * PLAYLIST
 */
export const playlistFetch = (page: number = 1, search: string = "") => {

    return async (dispatch: any, getState: any) => {

        dispatch( playlistCancelRequest() );
        if(page === 1) dispatch( playlistChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<PlaylistResponse> = await playlistService.fetch(page, search);
            let playlistResponse: PlaylistResponse = response.data;
            
            if(page === 1) dispatch( playlistFetchResponse(playlistResponse) );
            else dispatch( playlistFetchNextResponse(playlistResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( playlistChangeValue('isLoading', false) )
        }

    };

}

export const playlistFetchResponse = (payload: PlaylistResponse): ActionType<PlaylistResponse> => {
    return {
        type: actionTypes.PLAYLISTS_FETCH,
        payload,
    }
}

export const playlistFetchNextResponse = (payload: PlaylistResponse): ActionType<PlaylistResponse> => {
    return {
        type: actionTypes.PLAYLISTS_FETCH_NEXT,
        payload,
    }
}

export const playlistCancelRequest = () => {

    return async () => {
        playlistService?.cancelRequest();
        playlistService = new PlaylistService();
    };

}

export const playlistChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.PLAYLIST_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}

export const playlistSetCurrent = (playlist: Playlist | null): ActionType<any> => {
    return {
        type: actionTypes.PLAYLIST_SET_CURRENT,
        payload: {
            playlist
        },
    }
}

export const playlistStoreMedia = (formData: FormData) => {

    // return async (dispatch: any, getState: any) => {
        
    //     dispatch( playlistChangeValue('isLoading', true) )
        
    //     try {
    //         let response: AxiosResponse<PlaylistResponse> = await new PlaylistService().storeMediaAtPlaylist(formData)
    //         let playlistResponse: PlaylistResponse = response.data.data!
    //         dispatch( playlistFetchResponse(playlistResponse) );
    //         dispatch( playlistChangeValue('isLoading', false) )
    //     }
    //     catch(error) {
    //         dispatch( playlistChangeValue('isLoading', false) )
    //         console.error(error);
    //     }

    // };

}
