import Playlist from "./Playlist"; // Remove v2.0
export default interface Screen {
    id: string;
    user_id: string;
    name: string;
    license_id: string | null;
    pair_code: string | null;

    linked?: Playlist | null;
    linked_id?: string | null;
};

export function isScreenActive(screen: Screen) {
    return screen.license_id;
}

export function isScreenActiveAndSetPlaylist(screen: Screen) {
    return screen.linked_id && screen.license_id;
}

export function isScreenNotActive(screen: Screen) {
    return screen.pair_code && screen.license_id === null;
}
