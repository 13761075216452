import { AxiosResponse } from "axios";
import ActionType from "./Action";
import * as actionTypes from './types';
import ScreenService from "../services/use_case/screen/ScreenService";
import ScreenResponse from "../services/use_case/screen/ScreenResponse";
import Screen from "../models/Screen";
import CancelRequestError from "../services/CancelRequestError";

let screenService: ScreenService;

/*
 * SCREEN
 */
export const screenFetch = (page: number = 1, search: string = "") => {
    
    return async (dispatch: any, getState: any) => {
        
        dispatch( screenCancelRequest() );
        if(page === 1) dispatch( screenChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<ScreenResponse> = await screenService.fetch(page, search);
            let screenResponse: ScreenResponse = response.data;

            if(page === 1) dispatch( screenFetchResponse(screenResponse) );
            else dispatch( screenFetchNextResponse(screenResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( screenChangeValue('isLoading', false) );
        }

    };

}

export const screenFetchResponse = (payload: ScreenResponse): ActionType<ScreenResponse> => {
    return {
        type: actionTypes.SCREEN_FETCH,
        payload,
    }
}

export const screenFetchNextResponse = (payload: ScreenResponse): ActionType<ScreenResponse> => {
    return {
        type: actionTypes.SCREEN_FETCH_NEXT,
        payload,
    }
}

export const screenCancelRequest = () => {

    return async () => {
        screenService?.cancelRequest();
        screenService = new ScreenService();
    };

}

export const screenUpdate = (id: string, screen: Screen | FormData) => {

    return async (dispatch: any, getState: any) => {
    
        try {
            await new ScreenService().update(id, screen);
        }
        catch(error) {
            
        }

    };

}

export const screenChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.SCREEN_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}