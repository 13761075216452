import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import ApiResponse from "../../ApiResponse";
import PlaylistResponse from "./PlaylistResponse";
import Playlist from "../../../models/Playlist";
import PlaylistMedia from "../../../models/PlaylistMedia";

export default class PlaylistService extends BaseService {

    fetch(page: number = 1, search: string = ""): Promise<AxiosResponse<PlaylistResponse>> {
        let uri: string = `/playlists?page=${page}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    store(formData: FormData): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        return this.axios.post(`/playlists`, formData);
    }

    show(id: string): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        return this.axios.get(`/playlists/${id}`, this.getCancelTokenStructure() );
    }

    update(id: string, formData: FormData): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        return this.axios.put(`/playlists/${id}`, formData);
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        return this.axios.delete(`/playlists/${id}`);
    }

}
