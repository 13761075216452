export enum Environment {
    develop     = "http://localhost:1000",
    production  = "https://api.cactusds.com",
    staging     = "https://hp-api.cactusds.com:88"
};

export enum EnvironmentS3 {
    develop     = "http://localhost:3300",
    production  = "https://s3.cactusds.com",
    staging     = "https://0001bucket.cactusds.com"
};

export enum EnvironmentTimeService {
    develop     = "http://localhost:3400",
    production  = "https://time-service.cactusds.com",
    staging     = "https://time-service.cactusds.com"
};
