import { useTranslation } from 'react-i18next';

export default () => {
 
    const [t] = useTranslation();

    return {
        required: t('field_required').toString(), 
        minLength: {
            value: 6,
            message: "Password must have at least 6 characters"
        }
    };
  
}