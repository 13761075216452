import BaseService from "../../BaseService";
import LoginResponse from "./LoginResponse";
import RegisterResponse from "./RegisterResponse";
import { AxiosResponse } from "axios";

import { RegisterFormInputs } from "../../../components/RegisterFormPremium/RegisterFormPremium";

export default class AuthService extends BaseService {

    login(email: string, password: string): Promise<AxiosResponse<LoginResponse>> {
        return this.axios.post(`/auth/login`, {
            email,
            password,
        });
    }

    create(user: RegisterFormInputs): Promise<AxiosResponse<RegisterResponse>> {
        return this.axios.post(`/auth/register`, {
            ...user,
        });
    }

    me(): Promise<AxiosResponse<LoginResponse>> {
        return this.axios.post(`/auth/me`);
    }


}
