import { AxiosResponse } from "axios";
import CancelRequestError from "../services/CancelRequestError";
import HierarchyService from "../services/use_case/hierarchy/HierarchyService";
import HierarchyResponse from "../services/use_case/hierarchy/HierarchyResponse";
import ActionType from "./Action";
import * as actionTypes from './types';

let hierarchyService: HierarchyService;

/*
 * Hierarchy
 */
export const hierarchyFetch = () => {

    return async (dispatch: any, getState: any) => {

        dispatch( hierarchyCancelRequest() );
        
        dispatch( hierarchyChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<HierarchyResponse> = await hierarchyService.fetch();
            let hierarchyResponse: HierarchyResponse = response.data;
            
            dispatch( hierarchyFetchResponse(hierarchyResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( hierarchyChangeValue('isLoading', false) )
        }

    };

}

export const hierarchyFetchResponse = (payload: HierarchyResponse): ActionType<HierarchyResponse> => {
    return {
        type: actionTypes.HIERARCHY_FETCH,
        payload,
    }
}

export const hierarchyCancelRequest = () => {

    return async () => {
        hierarchyService?.cancelRequest();
        hierarchyService = new HierarchyService();
    };

}

export const hierarchyChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.HIERARCHY_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}