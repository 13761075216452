import * as actionTypes from '../actions/types';
import LoginResponse from '../services/use_case/auth/LoginResponse';
import User from "../models/User";

export interface AuthReducerState {
    readonly user: User | null;
    readonly token: string | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: AuthReducerState = {
    user: null,
    token: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.AUTH_LOGIN:
            let payload: LoginResponse = action.payload;

            return {
                ...state,
                user: payload.data?.user,
                token: payload.data?.access_token,
                isLoading: false,
            }

        case actionTypes.AUTH_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        case actionTypes.AUTH_LOGOUT:

            return {
                ...INITIAL_STATE
            }
    

        default:
            return state;
     
    }
}
