import React from 'react';
import Screen, { isScreenActive } from "../../models/Screen";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

interface DashboardScreenItemProps {
    screen: Screen
};

const DashboardScreenItem: React.FC<DashboardScreenItemProps> = (props: DashboardScreenItemProps) => {

    const _getRandomInt = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    const _renderImage = () => {

        const sizes: string[] = [
            '/img/printers/lg.png',
            '/img/printers/md.png',
            '/img/printers/sm.png',
            '/img/printers/xl.png',
        ];

        // return(
        //     <img
        //         src={ sizes[_getRandomInt( 0, sizes.length - 1)] }
        //         alt={props.screen.name}
        //     />
        // );

        return(
            <img
                src="/img/printers/md.png"
                alt={props.screen.name}
            />
        );


    }

    const _renderStatus = () => {

        let icon;

        if(isScreenActive(props.screen)) {
            icon = <FontAwesomeIcon icon={faCheckCircle} color="#3e884f" />;
        }
        else {
            icon = <FontAwesomeIcon icon={faTimesCircle} color="#8d4137" />;
        }

        return(
            <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                {icon}
            </div>
        );

    }

    return(
        <div className="card py-3 px-4 w-100 position-relative">
            {_renderImage()}
            <p className="mt-2 mb-0 text-center">{props.screen.name}</p>
            {_renderStatus()}
        </div>
    );

};

export default React.memo(DashboardScreenItem);
