import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    padding: 20px;
`;

const Title = styled.p`
    color: white;
    font-size: 1.3rem;
    margin: 0;
`;

const SubTitle = styled.p`
    font-size: .8rem;
    margin: 0;
`;

const Header = styled.div`
`;

const Body = styled.div`
    padding-top: 10px;
`;

const Circle = styled.div`
    max-width: 60px;
    max-height: 60px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #969696;
`;

interface DashboardItemProps {
    title: string;
    subtitle?: string | null | undefined;
    children?: React.ReactNode;
    value?: string | null;
};

const DashboardItem: React.FC<DashboardItemProps> = (props: DashboardItemProps) => {

    return(
        <Wrapper 
            className="card"
            {...props}
        >
            <div>
                <Header className="row">
                    <div className="col-sm-6">
                        <Title>{props.title}</Title>
                        <SubTitle>{props.subtitle}</SubTitle>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <Circle>{props.value}</Circle>
                    </div>
                </Header>
                {props.children ? <Body>{props.children}</Body> : null}
            </div>
        </Wrapper>
    );

};

export default React.memo(DashboardItem);
