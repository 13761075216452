export default interface Action<T> {
    readonly type: Symbol;
    readonly payload?: T | null;
}

type ChangeValue = {
    type: Symbol;
    key: string;
    value: any;
}

export const changeValue = (data: ChangeValue): Action<any> => {
    
    const { type, key, value } = data;

    return {
        type,
        payload: {
            key, 
            value
        },
    }
    
}
