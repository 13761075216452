import { useTranslation } from 'react-i18next';

export default () => {
 
    const [t] = useTranslation();

    return {
        pattern: {
            value: /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/i,
            message: t('invalid_cif')
        }
    };
  
}