import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import ApiResponse from "../../ApiResponse";
import Playlist from "../../../models/Playlist";

export default class ResourcePlaylistService extends BaseService {

    index(id: string): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        const uri: string = `/playlists/${id}/resource`;
        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    store(playlist_id: string, resource_id: string, resource_type: string): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        const uri: string = `/playlists/resource`;
        return this.axios.post(uri, {
            playlist_id,
            resource_id,
            resource_type,
        });
    }

    update(playlist_id: string, data: any): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        const uri: string = `/playlists/resource/${playlist_id}`;
        return this.axios.put(uri, {
            ...data,
        });
    }

    updateOrder(playlist_id: string, order_of_the_list: string[]): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        const uri: string = `/playlists/${playlist_id}/resource`;
        return this.axios.put(uri, {
            order_of_the_list,
        });
    }

    delete(id: string): Promise<AxiosResponse<ApiResponse<Playlist>>> {
        const uri: string = `/playlists/resource/${id}`;
        return this.axios.delete(uri);
    }

}
