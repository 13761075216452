import styled from "styled-components";

interface FilterButtonProps {
    active?: boolean;
}
export const FilterButton = styled.button`
    color: ${ (props: FilterButtonProps) => props.active ? "white" : "#bdafad"};
    margin-right: 5px;
    padding: 0.05rem 0.65rem;
    font-size: .75rem;
    &:hover {
        color: white;
    }
`