import React from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { PlaylistMediaItemTypes } from '../../containers/PlaylistDetail/PlaylistDetail';

interface PlaylistDropZoneDropItemProps {};

const PlaylistDropZoneDropItem: React.FC<PlaylistDropZoneDropItemProps> = (props: PlaylistDropZoneDropItemProps) => {

    const [t] = useTranslation();

    const [ , drop] = useDrop({
        accept: PlaylistMediaItemTypes.CARD,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        })
        
    });
    
    return(
        <div ref={drop} className="drop-here-area">
            <div className="drop-here-area__border">
                <div className="color-primary-color" style={{width: '300px',textAlign:'center', textShadow:'0 0 10px black' }}>
                    { t('Drop_item_here') }
                </div>
            </div>
        </div>
    );

};

export default React.memo(PlaylistDropZoneDropItem);
