import BaseService from "../../BaseService";
import DashboardResponse from "./DashboardResponse";
import { AxiosResponse } from "axios";

export default class DashboardService extends BaseService {

    fetch(): Promise<AxiosResponse<DashboardResponse>> {
        return this.axios.get(`/dashboard`, this.getCancelTokenStructure());
    }

}
