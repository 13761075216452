import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import Media, { MediaPreview } from '../../models/Media';
import Widget from '../../models/Widget';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudSun } from '@fortawesome/free-solid-svg-icons';

interface ResourceItemProps {
    resource: Media | Widget;
    originalMediaSize?: string;
    height?: string;
    width?: string;
    enableAutoPlayOnHover?: boolean;
    clipVideo?: boolean;
    silent?: boolean;
    widgetSize?: SizeProp;
};


const ResourceItem: React.FC<ResourceItemProps> = (props: ResourceItemProps) => {

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const videoEl = useRef<any>(null);

    const isEnableAutoPlayOnHover: boolean = props.enableAutoPlayOnHover ?? false;
    const isClipVideo: boolean = props.clipVideo ?? false;
    const isSilent: boolean = props.silent ?? false;

    const style = {
        media : {
            height: props.height || '65px',
            objectFit: 'cover',
            width: props.width || '100px'
        } as React.CSSProperties
    }

    const originalMediaSize: string = props.originalMediaSize ? `&size=${props.originalMediaSize}` : "";


    if(props.resource.mime_type.indexOf('widget/') > -1) {

        const typeArray: string[] = props.resource.mime_type.split("widget/");
        const type: string = typeArray[ typeArray.length - 1 ];
        
        switch(type) {
            case "time":
                return(
                    <div className="d-flex justify-content-center align-items-center" style={style.media}>
                        <FontAwesomeIcon
                            icon={faCloudSun}
                            size={props.widgetSize}
                        />
                    </div>
                );
            default:
                return(<div>Uups</div>);
        }

    }
    else if(props.resource.mime_type.indexOf('image') > -1){
        return (

            <img src={`${props.resource.full_path}${originalMediaSize}`} style={style.media}  alt={props.resource.name ?? ""} />
        )
    }
    else if (props.resource.mime_type.indexOf('video') > -1) {

        let url: string = `${props.resource.full_path}${originalMediaSize}`;

        if(isClipVideo) url += `&preview=${MediaPreview.clip}`;

        return (

            <div 
                onMouseOver={() => {
                    if(isEnableAutoPlayOnHover) setIsPlaying(!isPlaying);
                }}
                onMouseOut={() => {
                    if(isEnableAutoPlayOnHover) {
                        setIsPlaying(false);
                        videoEl.current?.seekTo(0);
                    }
                }}
                style={style.media}
            >
                <ReactPlayer
                    ref={videoEl}
                    width='100%'
                    height='100%'
                    url={url}
                    playing={isPlaying}
                    volume={isSilent ? 0 : 1}
                />
            </div>
        )
    }

    return(
        <div>
            Uups
        </div>
    );

};

export default React.memo(ResourceItem);
