import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconCactusLogoVertical } from '../../../assets/icons/IconCactusLogoVertical';
import RegisterFormPremium from "../../../components/RegisterFormPremium";
import { CactusModal } from "../../../components/";
import { replace } from "connected-react-router";


import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../../actions";

interface RegisterProps {};

const Register: React.FC<RegisterProps> = (props: RegisterProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();
    const { isLoading } = useSelector( (store: any) => store.authReducer);
    const [showCactusModal, setShowCactusModal] = useState<boolean>(false);

    return(
        <>
            <div className="auth">
                <div className='box-center'>

                    <div className='auth__box auth__box--large bg-gray-dark'>
                        <div className="row">
                            
                            <div className="col-12 col-md-6">
                                <div className="d-flex h-100">
                                    <IconCactusLogoVertical 
                                        class='fill-gray-light auth__logo w-100'
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 auth__form">
                                
                                <div className="mt-3">
                                    <RegisterFormPremium
                                        btnLoading={isLoading}
                                        onSubmit={(newUser) => {
                                            dispatch( actionCreator.userCreate(newUser, (success: boolean) => {
                                                if(success) { 
                                                    
                                                    setShowCactusModal(true);
                                                    
                                                }

                                            }) );
                                        }}
                                    />
                                </div>
                            </div>

                        </div>  
                    </div>

                </div>
            </div>

            <CactusModal 
                show={showCactusModal}
                //title={t('Create User')}
                onClose={() => {
                    setShowCactusModal(false);
                    dispatch( replace('/login'));
                }}
                width="70vw"
            >
                <p>{t('Wait to be validated by the administrator')}</p>
            </CactusModal>

        </>
    );

};

export default React.memo(Register);
