import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Option from "../../models/Option";

const selectStyles = {
    control: (styles: any, state: any) => ({ 
        ...styles,
        backgroundColor: '#232223',
        border: '1px solid #424242',
        boxShadow: state.isFocused ? null : null,
        fontSize: '.9rem',
        "&:hover": {
            borderColor: "#c0702f",
        },
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        // width: state.selectProps.width,
        backgroundColor: state.isDisabled ? '#969696' : '#232223',
        border: '1px solid #424242',
        color: '#969696',
        fontSize: '.9rem',
    }),
    option: (styles: any, meta: any) => {
        const { data, isDisabled/*, isFocused, isSelected*/ } = meta;
        const color = data.color;
        return {
            ...styles,
            backgroundColor: '#232223',
            cursor: isDisabled ? 'not-allowed' : 'default',
            color,
            "&:hover": {
                backgroundColor: "#c0702f",
                opacity: ".8",
                cursor: "pointer",
                color: "white",
            },
            "&:active": {
                backgroundColor: "transparent",
            }
        };
    },
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { 
            ...provided,
            opacity,
            transition,
            color: '#969696',
        };
    },
    indicatorSeparator: (provided: any, state: any) => {
        return { 
            ...provided,
            backgroundColor: '#424242'
        };
    },
    input: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: '#969696',
        }
    }
};

interface CustomSelectProps {
    options: Option[];
    value: any;
    onChange: any;
    widthDefaultOption?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = (props: CustomSelectProps) => {

    const [t] = useTranslation();
    let options: Option[] = props.options ?? [];
    
    const widthDefaultOption = props.widthDefaultOption ?? true;

    let myOtions: Option[] = widthDefaultOption ? [
        {label: "-", value: null},
        ...options
    ] : [...options];

    return(
        <Select
            {...props}
            options={myOtions}
            styles={selectStyles}
            noOptionsMessage={() => t('No Result')}
            placeholder="-"
        />
    );
}

export default React.memo(CustomSelect);
