import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import LoginForm, { LoginInputs } from "../../../components/LoginForm/LoginForm";
import * as actionCreator from "../../../actions";
import { IconCactusLogoVertical } from '../../../assets/icons/IconCactusLogoVertical';

interface LoginProps {};

const Login: React.FC<LoginProps> = (props: LoginProps) => {

    const dispath = useDispatch();
    const {isLoading} = useSelector( (store: any) => store.authReducer);

    const _handleOnSubmit = (data: LoginInputs) => {
        dispath( actionCreator.authLogin(data.email, data.password) );
    }
    
    return(
        <div className="auth">
            <div className='box-center'>

                <div className='auth__box bg-gray-dark'>
                    <div className="row">
                        
                        <div className="col-12 col-md-6">
                            <div className="d-flex h-100">
                                <IconCactusLogoVertical 
                                    class='fill-gray-light auth__logo w-100'
                                />
                            </div>
                        </div>

                        <div className="col-12 col-md-6 auth__form">
                            <LoginForm
                                onSubmit={_handleOnSubmit}
                                btnLoading={isLoading}
                            />
                        </div>

                    </div>  
                </div>

            </div>
        </div>
    );

};

export default React.memo(Login);
