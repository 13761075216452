import React, { useState } from 'react';
import { FlagEngland } from '../../assets/flags/FlagEngland';
import { FlagSpain } from '../../assets/flags/FlagSpain';
import i18n from '../../i18n';

interface LanguageSwitchProps {};

const box = {
    width: '15px',
    position: 'relative'
} as React.CSSProperties;

const styleFlags = {
    width: '100%'
}

// const submenu = {
//     position: 'absolute',
//     listStyle: 'none',
//     margin: '0',
//     padding: '0',
// } as React.CSSProperties;

// const submenuItem = {
//     width: '15px',
//     position: 'relative',
//     paddingTop: '10px'
// } as React.CSSProperties;


const LanguageSwitch: React.FC<LanguageSwitchProps> = (props: LanguageSwitchProps) => {

    const [lang, setLang] = useState<string>(localStorage.getItem("i18nextLng")?.split('-')[0] ?? "en");

    const _showFlag = () => {

        switch (lang) {
            case 'es':
                return <div className="pointer" onClick={ () => _handleSwitchLang('en') }><FlagSpain style={styleFlags} /></div>
            case 'en':
                return <div className="pointer" onClick={ () => _handleSwitchLang('es') } ><FlagEngland style={styleFlags} /></div>
            default:
                break;
        }
        
    }
    
    const _handleSwitchLang = (lang: string) => {
        i18n.changeLanguage(lang);
        setLang(lang);
    }

    return(
        <>
            <div style={box} >
                { _showFlag() }
                {/* <div>
                    <ul style={submenu}>
                        <li style={submenuItem}>
                            <FlagSpain style={styleFlags} />
                        </li>
                        <li style={submenuItem}>
                            <FlagEngland style={styleFlags} />
                        </li>
                    </ul>
                </div> */}
            </div>
        </>
    );

};

export default React.memo(LanguageSwitch);
