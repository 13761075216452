import BaseService from "../../BaseService";
import axios, { AxiosResponse } from "axios";
import PaginateResponse from "../../PaginateResponse";
import WidgetLayoutResponse from "./WidgetLayoutResponse";
import ApiResponse from "../../ApiResponse";
import Widget, { WidgetFilter } from "../../../models/Widget";

export default class WidgetService extends BaseService {

    fetch(page: number = 1, search: string, filter: string = WidgetFilter.all): Promise<AxiosResponse<PaginateResponse<Widget>>> {
        let uri: string = `/widgets?page=${page}&filter=${filter}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    show(id: string): Promise<AxiosResponse<ApiResponse<Widget>>> {
        return this.axios.get(`/widgets/${id}`);
    }

    store(widget: any): Promise<AxiosResponse<ApiResponse<Widget>>> {
        return this.axios.post(`/widgets`, {
            ...widget,
        });
    }

    update(id: string, data: any): Promise<AxiosResponse<ApiResponse<Widget>>> {
        return this.axios.put(`/widgets/${id}`, {...data});
    }

    delete(id: string): Promise<AxiosResponse<null>> {
        return this.axios.delete(`/widgets/${id}`);
    }

    fethLayouts(): Promise<ApiResponse<WidgetLayoutResponse>> {
        return axios.get(`${this.cactusTimeServiceBaseURL}/${this.prefix}/layouts`);
    }

}
