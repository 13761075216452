import { useState } from 'react';
import CheckableModel from '../models/CheckableModel';

type BatchActionDiccionary = {
    [key: string]: CheckableModel<any>
}

const useBatchAction = () => {
    
    const [isActive, setIsActive] = useState<boolean>(false);
    const [modelSelected, setModelSelected] = useState<BatchActionDiccionary>({});

    const reset = () => {
        setModelSelected({});
        setIsActive(false);
    }

    const getModelSelected = (): any[] => {

        let keys = Object.keys(modelSelected) ?? [];

        return keys.map((key: string) => modelSelected[key].model);

    }

    const isSelected = (): boolean => {
        let keys = Object.keys(modelSelected) ?? [];

        return keys.length > 0;
    }

    const isModelSelected = (model: any): boolean => {
        return modelSelected[model.id]?.checked === true;
    }

    const changeModelValue = (model: any) => {

        let copyModel = {...modelSelected};

        if(copyModel[model.id] && copyModel[model.id].checked) {
            delete copyModel[model.id];
        }
        else {
            copyModel[model.id] = {
                model,
                checked: true,
            }
        }

        setModelSelected(copyModel);

    }

    const isSelectAll = (dataSource: any[]): boolean => {

        let keys = Object.keys(modelSelected) ?? [];

        if(dataSource.length !== keys.length) return false;

        for(let i = 0; i < keys.length; i++) {
            if( !dataSource.find((source: any) => source.id === keys[i]) ) return false;
        }

        return true;

    }

    const selectAll = (dataSource: any[]) => {
        updateByDataSource(dataSource, true);
    }

    const deselectAll = () => {
        setModelSelected({});
    }

    const updateByDataSource = (dataSource: any[], checked: boolean) => {
        
        let copyModel = {...modelSelected};

        for(let i = 0; i < dataSource.length; i++) {

            let model: any = dataSource[i];

            copyModel[model.id] = {
                model,
                checked
            }

        }

        setModelSelected(copyModel);

    }

    return {
        isActive,
        setIsActive,
        isSelectAll,
        selectAll,
        deselectAll,
        reset,
        isSelected,
        isModelSelected,
        changeModelValue,
        getModelSelected,
    };
    
}

export default useBatchAction;
