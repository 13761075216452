import React from "react";
import { store } from '../store';
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute(props: any) {

    const {children, action, ...rest} = props;
    const { authReducer: { token, user } } = store.getState();

    const actions: string[] = user?.actions ?? [];

    return (
        <Route
            {...rest}
            render={({ history, location }) => {

                if(token) {

                    if(action) {
                        if(!actions.includes(action)) { // Don't have permission
                            history.goBack();
                            return;
                        }
                    }

                    return children;

                }

                return(
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                );

            }}
        />
    );

}