import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { 
    DashboardTitle,
    DashboardWrapper,
    OrganizationChartTemplate,
    HierarchyForm,
    CactusModal,
} from '../../components';

import Option from "../../models/Option";

import HierarchyModel, { getSubordinate, getHierarchyOption } from "../../models/Hierarchy";

import RegisterFormPremium, { RegisterFormInputs } from "../../components/RegisterFormPremium/RegisterFormPremium";

import { RightPanel } from "..";

import User from "../../models/User";
import { OrganizationChartData, getOrganizationChartData } from "../../models/Hierarchy";
import * as actionCreator from "../../actions";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
import OrganizationChart from "@dabeng/react-orgchart";

interface HierarchyProps {};

const Hierarchy: React.FC<HierarchyProps> = (props: HierarchyProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const authReducer = useSelector( (store: any) => store.authReducer );
    const isLoadingAuth: boolean = authReducer?.isLoading;

    const userReducer = useSelector( (store: any) => store.userReducer );
    const isLoadingUser: boolean = userReducer?.isLoading;

    const hierarchyReducer = useSelector( (store: any) => store.hierarchyReducer );
    
    const isLoadingHierarchy: boolean = hierarchyReducer?.isLoading;
    const data: HierarchyModel | null = hierarchyReducer?.data;

    const [showNewUserModal, setShowNewUserModal] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<OrganizationChartData | null>(null);
    const [subordinates, setSubordinates] = useState<Option[]>([]);
    const [nodeSelected, setNodeSelected] = useState<OrganizationChartData | null>(null);

    useEffect( () => {
        _fetchHierarchy();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect( () => {
        setDataSource( getOrganizationChartData(data) );
    }, [data]);

    useEffect( () => {
        if(dataSource) {

            const subordinates: HierarchyModel[] = [
                dataSource.data,
                ...getSubordinate(dataSource.data),
            ];

            setSubordinates(
                subordinates.map((data: HierarchyModel) => getHierarchyOption(data))
            );

        }
    }, [dataSource]);

    const _fetchHierarchy = () => {
        dispatch( actionCreator.hierarchyFetch() );
        _handleCloseModal();
    }

    const _renderOrganizationChart = () => {

        if(isLoadingHierarchy || !dataSource) return null;

        return(
            <>

                <OrganizationChart
                    datasource={dataSource}
                    NodeTemplate={OrganizationChartTemplate}
                    onClickChart={() => {
                        _handleCloseModal();
                    }}
                    onClickNode={(nodeData: OrganizationChartData) => {
                        setNodeSelected(nodeData);
                    }}
                    draggable={false}
                    multipleSelect={false}
                    pan={true}
                    zoom={true}
                    zoominLimit={2}
                />

            </>
        );
    }

    const _handleCloseModal = () => {
        setNodeSelected(null);
        setShowNewUserModal(false);
    }

    return(
        <DashboardWrapper>

            <div className="d-flex">
                
                <div id="center-panel">
                    
                    <DashboardTitle 
                        title={t('Hierarchy')}
                        isLoading={isLoadingHierarchy}
                        withRefreshIcon={!isLoadingHierarchy}
                        refreshIconCallBack={() => {
                            _fetchHierarchy();
                        }}
                    />

                </div>

            </div>
            
            <div className="hierarchy">

                <div className="mt-3" style={{ height: '60vh' }}>
                    
                    {_renderOrganizationChart()}

                </div>

            </div>

            <RightPanel opacity={nodeSelected ? 1 : 0}>
                
                <div className="d-flex align-items-center mb-3">
                    <span className="font-weight-bold">{t('Edit User')}</span>
                    <div 
                        className="ml-auto pointer p-2"
                        onClick={() => {
                            if(isLoadingUser) return;
                            _handleCloseModal();
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faTimes}
                            fill={isLoadingUser ? "#333333" : "#969696"}
                            size="1x"
                        />
                    </div>
                </div>

                <HierarchyForm
                    data={nodeSelected?.data ?? null}
                    subordinates={data?.id === nodeSelected?.id ? [] : subordinates}
                    btnLoading={isLoadingUser}
                    onSubmit={(data) => {

                        const updateUser: User = {
                            ...nodeSelected!.data,
                            ...data,
                        }

                        dispatch( actionCreator.updateUser(updateUser, () => {
                            _fetchHierarchy();
                        }) );

                    }}
                />

                <div className="p-3" style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <button
                        className={`btn btn-sm btn-cactus ${isLoadingUser ? "btn-cactus--loading" : ''} w-100`}
                        onClick={() => setShowNewUserModal(true)}
                        disabled={isLoadingUser}
                    >
                        {t('Create User')}
                    </button>
                </div>

            </RightPanel>

            <CactusModal 
                show={showNewUserModal}
                title={t('Create User')}
                onClose={() => {
                    setShowNewUserModal(false);
                }}
                width="70vw"
                disableOnClose={isLoadingAuth}
            >
                <RegisterFormPremium
                    showPremiumPartnerOption={false}
                    btnLoading={isLoadingAuth}
                    onSubmit={(data: RegisterFormInputs) => {
                        
                        const newUser: RegisterFormInputs = {
                            ...data,
                            user_id: nodeSelected?.data.id,
                        }

                        dispatch( actionCreator.userCreate(newUser, (isCreated: boolean) => {
                            if(isCreated) _fetchHierarchy();
                        }) );

                    }}
                />
            </CactusModal>
            

        </DashboardWrapper>
    );

};

export default React.memo(Hierarchy);
