import moment from "moment";
import PivotMediaPlaylist from "./PivotMediaPlaylist";

export enum MediaSize {
    large   = "large",
    medium  = "medium",
    small   = "small"
}

export enum MediaPreview {
    normal      = "normal",
    clip        = "clip",
}

export function isImage(mime_type: string | null | undefined): boolean {
    return mime_type?.startsWith("image/") ?? false;
}

export function isVideo(mime_type: string | null | undefined): boolean {
    return mime_type?.startsWith("video/") ?? false;
}

export function bytesToSize(bytes?: number, decimals: number = 2) {
    if(!bytes || bytes === 0) return '0 Bytes';

    const k: number = 1024;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i: number = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export enum MediaFilter {
    all     = "all",
    image   = "image",
    video   = "video",
}

export function getAllMediaFilters(): string[] {
    return Object.values(MediaFilter);
}

export function ifShowMediabyFilter(media: Media, filter: string): boolean {
    if(filter === MediaFilter.all) return true;
    return media.mime_type.startsWith(`${filter}/`) ?? false;
}

export function getSecondsFormattedIn(seconds: number, format: string = "mm:ss"): string {
    return moment.utc(seconds).format(format);
}

export function getDimensionFormatted(media: Media | null): string | null {

    if(!media) return null;
    
    if(media.width && media.height) return `${media.width} x ${media.height}`;

    return null;

}

export function getMediaDuration(value: number): number {
    return value / 1000;
}

export function setMediaDuration(value: number): number {
    return value * 1000;
}

export default interface Media {
    id: string;
    name: string;
    user_id: string;
    original_name: string;
    mime_type: string;
    size: number;
    path: string;
    full_path: string;
    file_duration: number;
    stored_id: string;
    visibility: string;
    description: string;
    updated_at: string;
    created_at: string;
    order?: number;
    pivot?: PivotMediaPlaylist;
    width?: number;
    height?: number;
};
