import React, { useState } from 'react';
import styled, { css } from "styled-components";
import { useDebounce } from "react-use";

interface SearchProps {
    borderColor?: string;
    placeholder?: string;
    onChange?: (text: string) => void;
    debounceFinish?: (text: string) => void;
};

const Search = styled.input`
    border-radius: 3px;

    ${ (props: SearchProps) => props.borderColor && css`
        border-color: ${props.borderColor};
    `}
`;

const SearchDebounce: React.FC<SearchProps> = (props: SearchProps) => {

    const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
    const [q, setQ] = useState<string>('');

    const [, ] = useDebounce(() => {
        
        if(isFirstTime) {
            setIsFirstTime(false);
        }
        else {
            props.debounceFinish?.(q);
        }

    }, 500, [q]);

    return(
        <>
            <Search
                type="text"
                placeholder={props.placeholder}
                className="w-100 form-control--dark"
                borderColor={props.borderColor}
                value={q}
                onChange={(e: any) => {
                    let search: string = e.target.value;
                    setQ(search);
                    props.onChange?.(search);
                }}
            />
        </>
    );

};

export default React.memo(SearchDebounce);
