import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../history';
import authReducer from './authReducer';
import screenReducer from './screenReducer';
import configReducer from './configReducer';
import scheduleReducer from './scheduleReducer';
import headerSubmenuReducer from './headerSubmenuReducer';
import mediaReducer from './mediaReducer';
import playlistReducer from './playlistReducer';
import widgetReducer from './widgetReducer';
import userReducer from './userReducer';
import hierarchyReducer from './hierarchyReducer';

/*
 * REDUX PERSIST
 */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // default: localStorage if web, AsyncStorage if react-native

const authReducerConfig = {
    key: 'auth',
    storage,
    blacklist: ['password', 'isLoading'],
}

const persistedAuthReducer = persistReducer(authReducerConfig, authReducer);

const rootReducer = combineReducers({
    router: connectRouter(history),
    authReducer: persistedAuthReducer,
    screenReducer,
    configReducer,
    scheduleReducer,
    headerSubmenuReducer,
    mediaReducer,
    playlistReducer,
    widgetReducer,
    userReducer,
    hierarchyReducer,
});

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: [ // "reducers" that you don't want to persist
        'router',
        'authReducer',
        'screenReducer',
        'configReducer',
        'scheduleReducer',
        'headerSubmenuReducer',
        'mediaReducer',
        'playlistReducer',
        'widgetReducer',
        'userReducer',
        'hierarchyReducer',
    ],
}

export default persistReducer(rootPersistConfig, rootReducer);