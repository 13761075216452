import { AxiosResponse } from "axios";
import CancelRequestError from "../services/CancelRequestError";
import MediaService from "../services/use_case/media/MediaService";
import MediaResponse from "../services/use_case/media/MediaResponse";
import ActionType from "./Action";
import * as actionTypes from './types';
import Media, { MediaFilter } from "../models/Media";

let mediaService: MediaService;

/*
 * MEDIA
 */
export const mediaFetch = (page: number = 1, search: string = "", filter: string = MediaFilter.all) => {

    return async (dispatch: any, getState: any) => {

        dispatch( mediaCancelRequest() );
        if(page === 1) dispatch( mediaChangeValue('isLoading', true) );
        
        try {
            let response: AxiosResponse<MediaResponse> = await mediaService.fetch(page, search, filter);
            let mediaResponse: MediaResponse = response.data;
            if(page === 1) dispatch( mediaFetchResponse(mediaResponse) );
            else dispatch( mediaFetchNextResponse(mediaResponse) );
        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) dispatch( mediaChangeValue('isLoading', false) );
        }

    };

}

export const mediaFetchResponse = (payload: MediaResponse): ActionType<MediaResponse> => {
    return {
        type: actionTypes.MEDIA_FETCH,
        payload,
    }
}

export const mediaFetchNextResponse = (payload: MediaResponse): ActionType<MediaResponse> => {
    return {
        type: actionTypes.MEDIA_FETCH_NEXT,
        payload,
    }
}

export const mediaCreate = (media: Media) => {
    return {
        type: actionTypes.MEDIA_CREATE,
        payload: media,
    }
}

export const mediaUpdate = (media: Media) => {
    return {
        type: actionTypes.MEDIA_UPDATE,
        payload: media,
    }
}

export const mediaCancelRequest = () => {

    return async () => {
        mediaService?.cancelRequest();
        mediaService = new MediaService();
    };

}

export const mediaChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.MEDIA_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}

export const mediaDelete = (media: Media[], callback?: Function) => {

    return async (dispatch: any, getState: any) => {
        
        dispatch( mediaChangeValue('isLoading', true) );

        if(!mediaService) mediaService = new MediaService();

        for(let i = 0; i < media.length; i++) {

            try {
                await mediaService.delete(media[i].id);
            }
            catch {}

        }

        dispatch( mediaChangeValue('isLoading', false) );
        dispatch( mediaFetch() );
        callback?.();

    }

}
