import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardTitle, DashboardWrapper } from '../../components';
import * as actionCreator from "../../actions";
import Config from '../../models/Config';
import ConfigItem from '../../components/ConfigItem/ConfigItem';
import ConfigItemSkeleton from "../../components/ConfigItem/ConfigItemSkeleton";


interface AdministationProps {};

const Administation: React.FC<AdministationProps> = (props: AdministationProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const configResponse = useSelector( (store: any) => store.configReducer )
    
    const isLoading = configResponse?.isLoading
    const data = configResponse?.data

    useEffect( () => {
        _fetchConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _fetchConfig = () => {
        dispatch( actionCreator.configFetch() );
    }

    const _renderSkeletonLoading = () => {
        let skeleton = Array.from({length: 15}, (_, i) => i + 1).map((item: any, id: number) => {
            return (
                <div key={id} className="mt-3">
                    <ConfigItemSkeleton />
                </div>
            );
        });

        return skeleton;
    }

    const _renderConfig = () => {

        if(isLoading) return _renderSkeletonLoading();

        let configs: JSX.Element[] = data?.map( (config: Config) => {
            return (
                <ConfigItem 
                    key={config.key}
                    config={config}
                />
            )
        } ) ?? [];

        return configs;

    }

    return(
        <DashboardWrapper>
            <DashboardTitle 
                title={t('Administration')} 
                isLoading={isLoading}
                withRefreshIcon={!isLoading}
                refreshIconCallBack={() => {
                    _fetchConfig();
                }}
            />
                {_renderConfig()}
        </DashboardWrapper>
    );

};

export default React.memo(Administation);
