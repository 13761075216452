import React from "react";
import { store } from '../store';
import { Route, Redirect } from "react-router-dom";

export default function AuthenticatedRoute(props: any) {

    const {children, ...rest} = props;
    const { authReducer: { token } } = store.getState();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { from: location }
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );

}