import * as actionTypes from '../actions/types';
import ConfigResponse from '../services/use_case/config/ConfigResponse';


export interface ConfigReducerState {
    readonly data: ConfigResponse | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: ConfigReducerState = {
    data: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.CONFIG_FETCH:
            let payload: ConfigResponse = action.payload;

            return {
                ...state,
                data: payload.data,
                isLoading: false,
            }
        case actionTypes.CONFIG_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
