import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardTitle, DashboardWrapper } from '../../components';
import * as actionCreator from "../../actions";

interface ScheduleProps {};

const Schedule: React.FC<ScheduleProps> = (props: ScheduleProps) => {

    const [t] = useTranslation();

    const dispatch = useDispatch();

    const scheduleResponse = useSelector( (state: any) => state.scheduleReducer)
    const { isLoading } = scheduleResponse

    console.log('scheduleFetch', scheduleResponse)

    useEffect( () => {
        dispatch( actionCreator.scheduleFetch() );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <DashboardWrapper>
            <DashboardTitle title={t('Schedule')} isLoading={isLoading}/>
        </DashboardWrapper>
    );

};

export default React.memo(Schedule);
