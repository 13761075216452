import React, { useEffect } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { ResourceItem } from '..';
import { PlaylistMediaItemTypes } from '../../containers/PlaylistDetail/PlaylistDetail';
import Media, { MediaSize } from '../../models/Media';
import Widget from '../../models/Widget';

interface PlaylistResourceItemProps {
    resource: Media | Widget;
    handleAddResource: (resource: Media | Widget | undefined) => void;
    handleDragMediaItems: Function;
};

export interface DragItem {
    data: Media | Widget | undefined
    type: string
    index?: any
}

const PlaylistResourceItem: React.FC<PlaylistResourceItemProps> = (props: PlaylistResourceItemProps) => {

    const [{ isDragging }, drag] = useDrag({
        item: { 
            type: PlaylistMediaItemTypes.CARD,
            data: props.resource
        },
        end: (item: DragItem | undefined, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                props.handleAddResource(item.data);
            }
        },
        collect: (monitor) => ({
          isDragging: !!monitor.isDragging()
        })
    })

    useEffect(() => {
        props.handleDragMediaItems(isDragging)
    }, [isDragging])
    

    return(
        <li
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: 'move',
            }}
            className="playlist-media-list__item"
        >
            
            <ResourceItem 
                resource={props.resource}
                width='65px'
                height='44px'   
                originalMediaSize={MediaSize.small} 
                enableAutoPlayOnHover
                clipVideo
                silent
            />

            <div className="playlist-media-list__item__info">
                <div className="playlist-media-list__item__info__name">
                    { props.resource.name }
                </div>
                <div className="playlist-media-list__item__info__type">
                    { props.resource.mime_type }
                </div>
            </div>
        </li>
    );

};

export default React.memo(PlaylistResourceItem);
