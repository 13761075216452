import { AxiosResponse } from "axios";
import CancelRequestError from "../services/CancelRequestError";
import User from "../models/User";
import UserService from "../services/use_case/user/UserService";
import UserResponse from "../services/use_case/user/UserResponse";
import ActionType, { changeValue } from "./Action";
import * as actionTypes from './types';

let userService: UserService;

export const updateUser = (user: User, callback?: () => void) => {

    return async (dispatch: any, getState: any) => {

        dispatch( userCancelRequest() );
        
        dispatch(
            changeValue({
                type: actionTypes.USER_CHANGE_VALUE,
                key: 'isLoading',
                value: true,
            })
        );

        try {
            let response: AxiosResponse<UserResponse> = await userService.update(user);

            dispatch(
                changeValue({
                    type: actionTypes.USER_CHANGE_VALUE,
                    key: 'isLoading',
                    value: false,
                })
            );

            callback?.();

        }
        catch(error) {
            dispatch(
                changeValue({
                    type: actionTypes.USER_CHANGE_VALUE,
                    key: 'isLoading',
                    value: false,
                })
            );
            
            callback?.();
        }

    };

}

export const userCancelRequest = () => {

    return async () => {
        userService?.cancelRequest();
        userService = new UserService();
    };

}
