import * as actionTypes from '../actions/types';
import Widget from '../models/Widget';
import PaginateResponse from '../services/PaginateResponse';


export interface WidgetReducerState {
    readonly data: PaginateResponse<Widget[]> | null;
    readonly items: Widget[];
    readonly message: string | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: WidgetReducerState = {
    data: null,
    items: [],
    message: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.WIDGET_FETCH:

            const fetchPaginate: PaginateResponse<Widget[]> | null = action.payload.data;

            return {
                ...state,
                data: fetchPaginate,
                items: fetchPaginate?.data ?? [],
                message: action.payload.message,
                isLoading: false,
            }

        case actionTypes.WIDGET_FETCH_NEXT:

            const fetchNextPaginate: PaginateResponse<Widget[]> | null = action.payload.data;
    
            return {
                ...state,
                data: fetchNextPaginate,
                items: state.items.concat(fetchNextPaginate?.data ?? []),
                message: action.payload.message,
                isLoading: false,
            }

        case actionTypes.WIDGET_CREATE:

            let newWidget: Widget = action.payload;

            return {
                ...state,
                items: [
                    newWidget,
                    ...state.items,
                ],
                isLoading: false,
            }

        case actionTypes.WIDGET_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
