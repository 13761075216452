import * as actionTypes from '../actions/types';
import Playlist from "../models/Playlist";
import PaginateResponse from '../services/PaginateResponse';

export interface PlaylistReducerState {
    readonly data: PaginateResponse<Playlist[]> | null;
    readonly current: Playlist | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: PlaylistReducerState = {
    data: null,
    current: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    
    switch(action.type) {

        case actionTypes.PLAYLISTS_FETCH:

            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
            }

        case actionTypes.PLAYLISTS_FETCH_NEXT:
            let actualPlaylist: Playlist[] = state?.data?.data ?? [];
            let payload: PaginateResponse<Playlist[]> = action.payload.data;

            if(action.payload.current_page <= state?.data?.current_page) {
                return {
                    ...state,
                }
            }

            let newPlaylist: any = payload.data ?? [];
            
            newPlaylist = actualPlaylist.concat( newPlaylist );
            payload.data = newPlaylist;

            return {
                ...state,
                data: payload,
                isLoading: false,
            }

        case actionTypes.PLAYLIST_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        case actionTypes.PLAYLIST_SET_CURRENT:
            return {
                ...state,
                current: action.payload.playlist,
            }

        default:
            return state;
        
    }
}
