import { AxiosResponse } from "axios";
import ActionType from "./Action";
import * as actionTypes from './types';
import CancelRequestError from "../services/CancelRequestError";
import AuthService from "../services/use_case/auth/AuthService";
import LoginResponse from "../services/use_case/auth/LoginResponse";
import { replace } from "connected-react-router";
import { persistor } from "../store";
import * as actionCreator from "../actions";

import { changeValue } from "./Action";
import { RegisterFormInputs } from "../components/RegisterFormPremium/RegisterFormPremium";
import RegisterResponse from "../services/use_case/auth/RegisterResponse";

let authService: AuthService;

/*
 * LOGIN
 */
export const authLogin = (email: string, password: string) => {

    return async (dispatch: any, getState: any) => {

        dispatch( authChangeValue('isLoading', true) );

        try {
            let response: AxiosResponse<LoginResponse> = await new AuthService().login(email, password);
            let loginResponse: LoginResponse = response.data;
            dispatch( authLoginResponse(loginResponse) );
            dispatch( replace('/dashboard') );
        }
        catch(error) {
            dispatch( authChangeValue('isLoading', false) );
        }

    };

}

export const authLoginResponse = (payload: LoginResponse): ActionType<LoginResponse> => {
    return {
        type: actionTypes.AUTH_LOGIN,
        payload,
    }
}

export const userCreate = (user: RegisterFormInputs, callback?: (success: boolean) => void) => {

    return async (dispatch: any, getState: any) => {

        dispatch( authCancelRequest() );

        dispatch(
            changeValue({
                type: actionTypes.AUTH_CHANGE_VALUE,
                key: 'isLoading',
                value: true,
            })
        );

        try {

            let response: AxiosResponse<RegisterResponse> = await authService.create(user);
            // let userResponse: UserResponse = response.data;

            dispatch( 
                changeValue({
                    type: actionTypes.AUTH_CHANGE_VALUE,
                    key: 'isLoading',
                    value: false,
                }) 
            );

            callback?.(true);

        }
        catch(error) {
            if( !(error instanceof CancelRequestError) ) {
                dispatch( 
                    changeValue({
                        type: actionTypes.AUTH_CHANGE_VALUE,
                        key: 'isLoading',
                        value: false,
                    }) 
                );
            }

            callback?.(false);
        }

    }
}

export const authCancelRequest = () => {

    return async () => {
        authService?.cancelRequest();
        authService = new AuthService();
    };

}

/*
 * LOGOUT
 */

 export const authLogout = () => {

    return async (dispatch: any, getState: any) => {

        dispatch( actionCreator.headerSubmenuChangeValue( false ) );
        await persistor.purge();
        dispatch( authLogoutResponse() );
        dispatch( replace('/login') );

    };

}

export const authLogoutResponse = (): ActionType<null> => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    }
}


export const authChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.AUTH_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}

export const authMe = () => {

    return async (dispatch: any, getState: any) => {

        dispatch( authChangeValue('isLoading', true) );

        try {
            let response: AxiosResponse<LoginResponse> = await new AuthService().me();
            let loginResponse: LoginResponse = response.data;
            dispatch( authLoginResponse(loginResponse) );
        }
        catch(error) {
            dispatch( authChangeValue('isLoading', false) );
        }

    };

}