import { AxiosResponse } from "axios";
import Config from "../models/Config";
import ConfigService from "../services/use_case/config/ConfigService";
import ActionType from "./Action";
import * as actionTypes from './types';

/*
 * CONFIG
 */
export const configFetch = () => {

    return async (dispatch: any, getState: any) => {
        
        dispatch( configChangeValue('isLoading', true) )
        
        try {
            let response: AxiosResponse<Array<Config>> = await new ConfigService().fetch();
            let configResponse: Array<Config> = response.data;
            dispatch( configFetchResponse(configResponse) );
            dispatch( configChangeValue('isLoading', false) )
        }
        catch(error) {
            dispatch( configChangeValue('isLoading', false) )
            console.error(error);
        }

    };

}

export const configFetchResponse = (payload: Array<Config>): ActionType<Array<Config>> => {
    return {
        type: actionTypes.CONFIG_FETCH,
        payload,
    }
}

export const configChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.CONFIG_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}
