import React, { useState, useRef } from 'react';
import styled from "styled-components";
import Media, { MediaSize, isImage, isVideo, bytesToSize, MediaPreview, getDimensionFormatted } from '../../models/Media';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { IconClose } from "../../assets/icons/IconClose";

const ImageWrapper = styled.div`
    background-color: #1b1b1b;
    height: 150px;
    max-height: 150px;
`;
    
const Image = styled.img`
    width: 100%;
    height: 150px;
    max-height: 150px;
    object-fit: cover;
`;

const Video = styled(ReactPlayer)`
    max-width: 100%;
    height: 150px;
    max-height: 150px;
`;

const Title = styled.p`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 1rem;
    margin-bottom: .5rem;
`;

const SubTitle = styled.p`
    color: #5a5a5a;
    font-size: .76rem;
    line-height: .9rem;
    font-weight: 300;
`;

const SelectedWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #1b191b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 1px 0px rgba(255, 255, 255, .5);
`;

interface MediaItemProps {
    media: Media;
    onSelect?: (media: Media) => void;
    visibleCheck?: boolean;
    isChecked?: boolean;
    onChecked?: (media: Media) => void;
    enableAutoPlayOnHover?: boolean;
    clipVideo?: boolean;
    silent?: boolean;
};

const MediaItem: React.FC<MediaItemProps> = (props: MediaItemProps) => {

    const [t] = useTranslation();

    let media: Media = props.media;
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const videoEl = useRef<any>(null);
    const isEnableAutoPlayOnHover: boolean = props.enableAutoPlayOnHover ?? false;
    const isClipVideo: boolean = props.clipVideo ?? false;
    const isSilent: boolean = props.silent ?? false;

    const _renderResource = () => {
        
        let component: React.ReactNode = null;
        let componentAction: any = null;

        if(isImage(media.mime_type)) {
            component = (
                <Image
                    src={`${props.media.full_path}&size=${MediaSize.small}`}
                    alt={props.media.name}
                />
            );
            componentAction = () => props.onSelect?.(props.media);
        }
        else if(isVideo(media.mime_type)) {

            let url: string = `${props.media.full_path}&size=${MediaSize.small}`;

            if(isClipVideo) url += `&preview=${MediaPreview.clip}`;

            component = (
                <div
                    onMouseOver={() => {
                        if(isEnableAutoPlayOnHover) {
                            setIsPlaying(true);
                        }
                    }}
                    onMouseOut={() => {
                        if(isEnableAutoPlayOnHover) {
                            setIsPlaying(false);
                            videoEl.current?.seekTo(0);
                        }
                    }}
                >
                    <Video 
                        ref={videoEl}
                        url={url}
                        playing={isPlaying}
                        volume={isSilent ? 0 : 1}
                        // controls
                        // config={{
                        //     file: { 
                        //         attributes: {
                        //             controlsList: "nofullscreen"
                        //         }
                        //     }
                        // }}
                    />
                </div>
            );
        }

        return (
            <div className="pointer" onClick={componentAction}>
                {component}
            </div>
        );

    }

    const _handleSelection = () => {

        const isChecked = props.isChecked ?? false;
        const visibleCheck = props.visibleCheck ?? false;

        if(!visibleCheck) return null;

        const icon = isChecked ? (
            <IconClose
                styles={{ width: '15px' }}
                fill={"#969696"}
            />
        ) : null;

        return(
            <SelectedWrapper onClick={() => props.onChecked?.(media)}>
                {icon}
            </SelectedWrapper>
        );

    }

    const _renderDimension = () => {
        
        let text: string = `${t('mediaitem_dimension')}:`;
        let dimensionText: string | null = getDimensionFormatted(props.media);
        
        if(dimensionText) text += ` ${dimensionText}`;

        return(
            <SubTitle className="mb-0">{text}</SubTitle>
        );

    }

    return(
        <div className="media-item card">
            <ImageWrapper>
                {_renderResource()}
            </ImageWrapper>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <a 
                            href="!#"
                            onClick={(e) => e.preventDefault()}
                        >
                            <Title title={props.media.original_name} onClick={() => props.onSelect?.(props.media)}>{props.media.name}</Title>
                        </a>
                        <SubTitle className="mb-0">{t('mediaitem_size')}: {bytesToSize(props.media.size)}</SubTitle>
                        {/* <SubTitle className="mb-0">{t('mediaitem_duration')}: { getSecondsFormattedIn(props.media.file_duration ?? 0) }</SubTitle> */}
                        {_renderDimension()}
                    </div>
                </div>
            </div>
            {_handleSelection()}
        </div>
    );

};

export default React.memo(MediaItem);
