import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const ActionButton = styled.button`
    color: #bdafad;
    font-size: .75rem;
    padding: 0.05rem 0.65rem;
`

interface BatchActionProps {
    children?: React.ReactNode;
    isDisabled?: boolean;
    isActive?: boolean;
    onClickToggleAction?: () => void;
    selectAll?: boolean;
    onClickSelectAll?: (all: boolean) => void;
};

const BatchAction: React.FC<BatchActionProps> = (props: BatchActionProps) => {

    const [t] = useTranslation();

    const isDisabled = props.isDisabled ?? false;
    const isActive = props.isActive ?? false;
    const selectAll = props.selectAll ?? false;

    const _renderContent = () => {

        if(!isActive) {
            return(
                <div className="d-flex justify-content-end">
                    <ActionButton 
                        className="btn btn-xs btn-outline-dark"
                        onClick={() => props.onClickToggleAction?.()}
                        disabled={isDisabled}
                    >
                        {t('Batch Action')}
                    </ActionButton>
                </div>
            );
        }

        return(
            <>

                <div className="d-flex justify-content-between">

                    <div className="d-flex">
                        
                        <ActionButton
                            className="d-flex align-items-center btn btn-dark d-flex align-item-center"
                            onClick={() => props.onClickSelectAll?.(!selectAll)}
                        >
                            
                            <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={() => {}}
                            />

                            <span className="ml-1">{t('Select All')}</span>

                        </ActionButton>

                    </div>

                    <ActionButton 
                        className="btn btn-xs btn-outline-dark ml-1"
                        onClick={() => props.onClickToggleAction?.()}
                        disabled={isDisabled}
                    >
                        {t('Cancel')}
                    </ActionButton>

                </div>

                <div className="mt-3 p-3 bg-black-light">
                    {props.children}
                </div>

            </>
        );

    }

    return(
        <div className="bg-gray-dark p-3">
            {_renderContent()}
        </div>
    );

};

export default React.memo(BatchAction);
