import Media from "./Media";
import Widget from "./Widget";

export default interface ResourceItem {
    id: string;
    playlist_id: string;
    resource_type: string;
    resource_id: string;
    order: number;
    duration: number;
    created_at: string;
    updated_at: string;
    resource: Media | Widget;
};

export function getResourceDuration(value: number): number {
    return value / 1000;
}

export function setResourceDuration(value: number): number {
    return value * 1000;
}
