import BaseService from "../../BaseService";
import User from '../../../models/User';
import { AxiosResponse } from "axios";
import UserResponse from "./UserResponse";
import { store } from '../../../store';

export default class UserService extends BaseService {

    update(user: User): Promise<AxiosResponse<UserResponse>> {
        return this.axios.put(`/users/${user.id}`, {
            ...user,
        });
    }

}
