import * as actionTypes from '../actions/types';
import User from "../models/User";

export interface UserReducerState {
    readonly users: User[];
    readonly isLoading: boolean;
}

const INITIAL_STATE: UserReducerState = {
    users: [],
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.USER_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
     
    }
}
