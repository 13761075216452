import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import allReducers from '../reducers';
import { persistStore } from 'redux-persist';
import history from "../history";
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

let middlewares = [
    ReduxThunk,
    routerMiddleware(history)
];

if(process.env.NODE_ENV === 'development') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

let enhancer = compose(applyMiddleware(...middlewares));

if(process.env.NODE_ENV === 'development') {
    enhancer = compose(composeWithDevTools(applyMiddleware(...middlewares)));
}

const store = createStore(
    allReducers,
    enhancer,
);
const persistor = persistStore(store);

export {
    store,
    persistor,
}
