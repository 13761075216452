import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import './i18n';
import ReactGA from 'react-ga';
import Router from './Router';

import 'izitoast/dist/css/iziToast.min.css';
import './assets/sass/styles.scss';

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`, {
  debug: process.env.NODE_ENV !== 'production',
});

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
