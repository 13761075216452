import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Media, { isVideo } from '../../models/Media';
import Dropzone, { IFileWithMeta, IPreviewProps, ILayoutProps, StatusValue } from 'react-dropzone-uploader';
import MediaService from "../../services/use_case/media/MediaService";
import ReactPlayer from 'react-player';
import ApiResponse from '../../services/ApiResponse';

const DropzoneItem = (props: IPreviewProps) => {

    const [t] = useTranslation();

    const { name, percent, status, type, previewUrl } = props.meta;
    const { file, cancel, remove } = props.fileWithMeta;

    let resourceComponent: any = (<img className="dropzone-item__img" src={previewUrl} alt={name} title={name} />);
    if(isVideo(type)) {
        resourceComponent = <div className="dropzone-item__img"></div>;
        if(status === "done") {
            let url = URL.createObjectURL(file);
            resourceComponent = (
                <div className="dropzone-item__img">
                    <ReactPlayer url={url} controls width='100%' height='100%' />
                </div>
            );
        }
    }

    let progressClassStr: string = "dropzone-item__progress";
    if(status === "uploading") progressClassStr += " dropzone-item__progress--active";

    let errorComponent = null;
    if(status.startsWith("rejected_") || status.startsWith("error_") || status.startsWith("exception_")) {
        errorComponent = (
            <div className="dropzone-item__error">
                <p className="font-weight-bold ellipsize w100">{name}</p>
                <p className="m-0">{t('exception_upload_file')}</p>
            </div>
        );
    }

    return (
        <div className="dropzone-item">

            {resourceComponent}
            
            <div className={progressClassStr}>
                <progress className="" max="100" value={Math.round(percent)}></progress>
                <button 
                    className="btn btn-cactus btn-cactus--active mt-1"
                    onClick={() => {
                        cancel();
                        remove();
                    }}
                >
                    {t('Cancel')}
                </button>
            </div>

            {errorComponent}

        </div>
    );

}

const Layout = (props: ILayoutProps) => {

    const { input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } } = props;

    return (
        <div {...dropzoneProps}>
            {previews}

            {files.length < maxFiles && input}

            {files.length > 0 && submitButton}
        </div>
    );
}

interface CreateLibraryProps {
    disableOnClose?: (value: boolean) => void;
    handleNewMedia?: (media: Media) => void;
};

const CreateLibrary: React.FC<CreateLibraryProps> = (props: CreateLibraryProps) => {

    const [t] = useTranslation();
    const mediaService: MediaService = new MediaService();
    const dropzoneEl = useRef<any>();

    useEffect(() => {

        let dropzone = dropzoneEl.current;

        dropzone.querySelector(".dzu-dropzone").addEventListener('dragover', _onDragOver);
        dropzone.querySelector(".dzu-dropzone").addEventListener('dragleave', _onDragLeave);

        return () => {
            dropzone.querySelector(".dzu-dropzone").removeEventListener('dragover', _onDragOver);
            dropzone.querySelector(".dzu-dropzone").removeEventListener('dragleave', _onDragLeave);
        }

    }, []);

    const _onDragOver = () => {
        dropzoneEl.current.querySelector(".dzu-dropzone").classList.add("dzu-dropzone--active");
    }

    const _onDragLeave = () => {
        dropzoneEl.current.querySelector(".dzu-dropzone").classList.remove("dzu-dropzone--active");
    }

    // specify upload params and url for your files
    const _getUploadParams = (file: IFileWithMeta) => {
        
        const body = new FormData();
        body.append('file', file.file);
        body.append('name', file.meta.name);

        return {
            url: `${mediaService.baseURL}/medias`,
            headers: {
                "Authorization": `Bearer ${mediaService.token}`,
                'Access-Control-Allow-Origin': '*',
            },
            body,
        }
    }

    const _handleChangeStatus = (file: IFileWithMeta, status: StatusValue) => {
        if(status === "done" && file.meta.percent === 100) {
            try {
                let responseStr: string = file.xhr?.response ?? "";
                let response: ApiResponse<Media> = JSON.parse(responseStr);
                let media: Media | null = response.data;
                if(media) props.handleNewMedia?.(media);
            }
            catch(error) { }
        }
    }

    return(
        <div className="dropzone" ref={dropzoneEl}>
            <Dropzone
                accept="image/*,video/*"
                getUploadParams={_getUploadParams}
                PreviewComponent={DropzoneItem}
                onChangeStatus={_handleChangeStatus}
                multiple={false}
                autoUpload={true}
                SubmitButtonComponent={() => null}
                inputContent={t('dropzone_text')}
                inputWithFilesContent="+"
                LayoutComponent={Layout}
                styles={{
                    dropzone: {
                        minHeight: '40vh',
                        border: '1px dashed rgba(0, 0, 0, .5)',
                    },
                    dropzoneActive: {
                        backgroundColor: 'transparent',
                    },
                }}
            />
        </div>
    );

};

export default React.memo(CreateLibrary);
