import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetSelection, getAllWidgetSelection } from "../../models/Widget";
import Widget from "../../models/Widget";
import Option from "../../models/Option";
import {
    DesignerRightPanel,
    FormWidgetTime,
} from "../index";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudSun, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface WidgetDesignerRightPanelProps {
    isOpen: boolean;
    onClose: () => void;
    createWidget?: (widget: Widget) => void;
    disableSubmitButton?: boolean;
};

const WidgetDesignerRightPanel: React.FC<WidgetDesignerRightPanelProps> = (props: WidgetDesignerRightPanelProps) => {

    const [t] = useTranslation();

    const isOpen: boolean = props.isOpen;
    const disableSubmitButton: boolean = props.disableSubmitButton ?? false;

    const [widgetSelected, setWidgetSelected] = useState<WidgetSelection | null>(null);

    useEffect(() => {
        setWidgetSelected(null);
    }, [isOpen]);

    const _renderBody = () => {

        let navigation;
        let content;

        if(widgetSelected) {
            navigation = (
                <div className="pointer" onClick={() => setWidgetSelected(null)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </div>
            );
            content = _renderWidgetSelected();
        }
        else {
            content = _renderWidgetNotSelected();
        }

        return(
            <>
                {navigation}
                <div className="mt-3">
                    {content}
                </div>
            </>
        );

    }

    const _renderWidgetSelected = () => {

        let icon;

        if(widgetSelected) icon = _getIcon(widgetSelected, "1x");

        return(
            <>
                <div className="d-flex align-items-center">
                    <h5 className="m-0 mr-3">{ t('Create Widget') }</h5>
                    {icon}
                </div>
                <div className="mt-3">
                    <FormWidgetTime
                        widgetSelected={widgetSelected}
                        disableSubmitButton={disableSubmitButton}
                        createWidget={(widget: Widget) => props.createWidget?.(widget)}
                    />
                </div>
            </>
        );
    }

    const _renderWidgetNotSelected = () => {
        return(
            <>
                <h5 className="m-0">{ t('Select Widget') }</h5>
                <div className="mt-3">
                    {_renderWidgets()}
                </div>
            </>
        );
    }

    const _getIcon = (item: WidgetSelection, size: SizeProp = "1x") => {

        return <FontAwesomeIcon icon={faCloudSun} size={size} />;

    }

    const _renderWidgets = () => {

        const widgets = getAllWidgetSelection().map((item: WidgetSelection, i: number) => {

            let icon = _getIcon(item, "2x");
            let text: string = t('filter_button_widget/time');

            return(
                <div 
                    key={i}
                    className="d-flex justify-content-center align-items-center flex-column bg-gray-dark pointer"
                    style={{ width: '100px', height: '100px' }}
                    onClick={() => setWidgetSelected(item)}
                >
                    {icon}
                    <span className="mt-1" style={{ fontSize: '.9rem' }}>{text}</span>
                </div>
            );
        });

        return(
            <div className="d-flex">
                {widgets}
            </div>
        );

    }

    return(
        <>
            <DesignerRightPanel
                show={isOpen}
                shouldCloseOnOverlayClick={true}
                onClose={() => props.onClose()}
                minWidth="40vw"
                width="40vw"
            >
                {_renderBody()}
            </DesignerRightPanel>
        </>
    );

};

export default React.memo(WidgetDesignerRightPanel);
