import * as actionTypes from '../actions/types';
import Media from '../models/Media';
import PaginateResponse from '../services/PaginateResponse';


export interface MediaReducerState {
    readonly data: PaginateResponse<Media[]> | null;
    readonly message: string | null;
    readonly isLoading: boolean;
    readonly isModified: boolean;
}

const INITIAL_STATE: MediaReducerState = {
    data: null,
    message: null,
    isLoading: false,
    isModified: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.MEDIA_FETCH:

            return {
                ...state,
                data: action.payload.data,
                message: action.payload.message,
                isLoading: false,
            }

        case actionTypes.MEDIA_FETCH_NEXT:

            let actualMedia: any = state?.data?.data ?? [];
            let payload: PaginateResponse<Media[]> = action.payload.data;
            let fetchMedia: any = payload.data ?? [];

            if(action.payload.current_page <= state?.data?.current_page) {
                return {
                    ...state,
                }
            }

            fetchMedia = actualMedia.concat( fetchMedia );
            payload.data = fetchMedia;
    
            return {
                ...state,
                data: payload,
                message: action.payload.message,
                isLoading: false,
            }

        case actionTypes.MEDIA_CREATE:

            let newMedia: Media = action.payload;
            let createPaginate: any = state.data;
            let createData: any = createPaginate?.data ?? [];

            createPaginate.data = [newMedia].concat(createData);

            return {
                ...state,
                data: createPaginate,
            }

        case actionTypes.MEDIA_UPDATE:

            let updatedMedia: Media = action.payload;
            let updatePaginate: any = state.data;
            let updateData: any = updatePaginate?.data ?? [];

            for(let i = 0; i < updateData.length; i++) {
                if(updateData[i].id === updatedMedia.id) {
                    updateData[i] = updatedMedia;
                    break;
                }
            }

            updatePaginate.data = updateData;

            return {
                ...state,
                data: updatePaginate,
            }

        case actionTypes.MEDIA_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
