import React from 'react';
import { useTranslation } from 'react-i18next';

interface ScreenPairCodeProps {
    paircode: string | null | undefined;
};

const ScreenPairCode: React.FC<ScreenPairCodeProps> = (props: ScreenPairCodeProps) => {

    const [t] = useTranslation();

    return(
        <div className="col-12 screen_paircode">
            <div className="screen_paircode__title">
                {t('Pair_code')}:
            </div>
            <div className="screen_paircode__code">
                { 
                    props.paircode
                }
            </div>
        </div>
    );

};

export default React.memo(ScreenPairCode);
