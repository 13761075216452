import React from 'react';

interface RightPanelProps {
    children?: React.ReactNode;
    opacity?: number;
};

const RightPanel: React.FC<RightPanelProps> = (props: RightPanelProps) => {

    const opacity: number = props.opacity ?? 1;
    const transformStyle: string = opacity ? "translateX(0)" : "translateX(100vw)";

    return(
        <div id="right-panel" className="card" style={{ opacity: opacity, transition: 'all .3s ease', transform: transformStyle }}>
            <div className="col-12 pt-3">
                { props.children }
            </div>
        </div>
    );

};

export default React.memo(RightPanel);
