import UserConfig from "./UserConfig";
import Screen from "./Screen";

export function getFullName(user: User | null): string {
    if(!user) return "";
    return `${user.name ?? ""} ${user.last_name ?? ""}`;
}

export function isSuperAdmin(rol: string[]) {
    return rol.includes("super-admin");
}

export function isPremiumPartner(rol: string[]) {
    return rol.includes("premium-partner");
}

export function isCorporate(rol: string[]) {
    return rol.includes("corporate");
}

export function isManager(rol: string[]) {
    return rol.includes("manager");
}

export function isClient(rol: string[]) {
    return rol.includes("client");
}

export function isBasicUser(rol: string[]) {
    return rol.includes("basic");
}
export default interface User {
    id: string;
    user_id: string | null;
    name: string;
    last_name: string | null;
    email: string;
    created_at: string;
    updated_at: string;
    rol: string[];
    actions: Array<string>;
    user_config: Array<UserConfig>;
    User: User | null;
    screens: Screen[] | null;
};
