import * as actionTypes from '../actions/types';
import ScheduleResponse from '../services/use_case/schedule/ScheduleResponse';



export interface ScheduleReducerState {
    readonly data: ScheduleResponse | null;
    readonly message: ScheduleResponse | null;
    readonly isLoading: boolean;
}

const INITIAL_STATE: ScheduleReducerState = {
    data: null,
    message: null,
    isLoading: false,
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.SCHEDULE_FETCH:
            let payload: ScheduleResponse = action.payload;

            return {
                ...state,
                data: payload.data,
                message: payload.message,
                isLoading: false,
            }
        case actionTypes.SCHEDULE_CHANGE_VALUE:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }

        default:
            return state;
        
    }
}
