import React from 'react';
import { useTranslation } from 'react-i18next';
import CactusModal from '../CactusModal';

interface ModalDeleteConfirmProps {
    show: boolean;
    title?: string;
    onCloseFunction: Function;
    onCloseFunctionParameter: any;
    overrideEntityTranslationAbove?: string;
    entityTranslation: string;
    buttonLoading: boolean;
    onClickFunctionButton: any;
    buttonText: string;
};

const ModalDeleteConfirm: React.FC<ModalDeleteConfirmProps> = (props: ModalDeleteConfirmProps) => {

    const [t] = useTranslation();
    const aboveText: string = props.overrideEntityTranslationAbove ? 
        props.overrideEntityTranslationAbove : 
        t('Are_you_sure_to_delete_current_f_');

    return(
        <CactusModal
            show={props.show}
            title={props.title}
            onClose={ () => props.onCloseFunction(props.onCloseFunctionParameter) }
            minWidth="300px"
        >
            <div className="row">
                <div className="col-12">
                    <p>{ aboveText + props.entityTranslation }?</p>
                </div>
                <div className="col-12 text-right">
                    <button
                        className={`btn btn-cactus--red ${props.buttonLoading && 'btn-cactus--red--loading'}`}
                        disabled={props.buttonLoading ?? false}
                        onClick={props.onClickFunctionButton}
                    >
                        { props.buttonText }
                    </button>
                </div>
            </div>
        </CactusModal>
    );

};

export default React.memo(ModalDeleteConfirm);
