export function isWidgetTime(mime_type: string | null | undefined): boolean {
    return mime_type?.startsWith("widget/time") ?? false;
}

export enum WidgetFilter {
    all     = "all",
    time    = "widget/time",
}

export function getAllWidgetFilters(): string[] {
    return Object.values(WidgetFilter);
}

export default interface Widget {
    id: string;
    name: string;
    user_id: string;
    original_name: string;
    mime_type: string;
    path: string;
    full_path: string;
    file_duration: number;
    description: string;
    updated_at: string;
    created_at: string;
    order?: number;
    width?: number;
    height?: number;
};

export interface WidgetSelection {
    mime_type: string;
    data?: Widget;
}

export function getAllWidgetSelection(): WidgetSelection[] {
    return [
        { mime_type: WidgetFilter.time },
    ];
}
