import React, { useState, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { CactusLabelInput } from "../../components";
import { useForm } from "react-hook-form";
import ReactDOM from "react-dom";

import useFormValidatorEmail from '../../hooks/validators/use-form-validator-email';
import useFormValidatorText from '../../hooks/validators/use-form-validator-text';
import useFormValidatorPassword from '../../hooks/validators/use-form-validator-password';
import useFormValidatorCif from '../../hooks/validators/use-form-validator-cif';

export type RegisterFormInputs = {
    user_id?: string | null | undefined; 
    name: string;
    last_name?: string | null;
    email: string;
    password: string;
    passwordRepeat: string;
    cif: string;
    postal_code: string;
    isPremiumPartner?: boolean;
}

interface RegisterFormPremiumProps {
    onSubmit: (data: RegisterFormInputs) => void;
    btnLoading?: boolean;
    showPremiumPartnerOption?: boolean;
};

const RegisterFormPremium: React.FC<RegisterFormPremiumProps> = (props: RegisterFormPremiumProps) => {

    const [t] = useTranslation();
    const { handleSubmit, register, errors, getValues } = useForm<RegisterFormInputs>();

    const showPremiumPartnerOption = props.showPremiumPartnerOption ?? true;

    const requiredRegister = register(useFormValidatorText());
    const emailRegister = register(useFormValidatorEmail());
    const passwordRegister = register(useFormValidatorPassword());
    const cifRegister = register(useFormValidatorCif());
    const passwordRepeatRegister = register({
        validate: value => value === getValues('password') || t("The passwords do not match").toString()
    });

    const _handleOnSubmit = (data: RegisterFormInputs) => {
        props.onSubmit(data);
    }

    const _renderBtnSubmit = () => {
        return (
            <div className="col-12">
                <button 
                    data-testid="submit"
                    type="submit"
                    className="btn btn-cactus mt-2"
                    disabled={props.btnLoading ?? false}
                >
                    {t('Create')}
                </button>
            </div>
        );
    }

    const [isPremiumPartner, setPremiumPartner] = useState<boolean>(false)

    const handlePremiumPartner = () => {
        setPremiumPartner(!isPremiumPartner);
    }

    const renderPremiumPartnerFields = () => {

        if(!isPremiumPartner) return null;

        return(
            <>
                <div className="col-12 col-md-6">
                    <CactusLabelInput
                        labelTitle={t('Postal Code')}
                        inputAttributes={{
                            "data-testid": "postal_code",
                            id: "postal_code",
                            name: "postal_code",
                            type:  "text",
                            placeholder: "",
                            autoComplete: "",
                            ref: requiredRegister,
                        }}
                        errorMessage={errors.postal_code ? errors.postal_code.message : null}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <CactusLabelInput
                        labelTitle={'CIF'}
                        inputAttributes={{
                            "data-testid": "cif",
                            id: "cif",
                            name: "cif",
                            type:  "text",
                            placeholder: "",
                            autoComplete: "text",
                            ref: cifRegister,
                        }}
                        errorMessage={errors.cif ? errors.cif.message : null}
                    />
                </div>
            </>
        );

    }

    const _renderPremiumPartnerField = () => {

        if(!showPremiumPartnerOption) return null;

        return(
            <>
                
                <div className="col-12 mt-2">
                    <label>
                        <input
                            id = "isPremiumPartner"
                            name = "isPremiumPartner"
                            type = "checkbox"
                            onClick={handlePremiumPartner}
                            ref={register()}
                        /> {t("I'm Premium Partner")}
                    </label>
                </div>
                
                {renderPremiumPartnerFields()}
            </>
        );

    }

    return(
        <form onSubmit={handleSubmit(_handleOnSubmit)}>

            <div className="row">
                <div className="col-12 col-md-6">
                    <CactusLabelInput
                        labelTitle={t('Name')}
                        inputAttributes={{
                            "data-testid": "name",
                            id: "name",
                            name: "name",
                            type:  "text",
                            placeholder: "",
                            autoComplete: "",
                            ref: requiredRegister,
                        }}
                        errorMessage={errors.name ? errors.name.message : null}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <CactusLabelInput
                        labelTitle={t('Last Name')}
                        inputAttributes={{
                            "data-testid": "last_name",
                            id: "last_name",
                            name: "last_name",
                            type:  "text",
                            placeholder: "",
                            autoComplete: "",
                            ref: register(),
                        }}
                        errorMessage={errors.last_name ? errors.last_name.message : null}
                    />
                </div>

                <div className="col-12 mt-2">
                    <CactusLabelInput
                        labelTitle={t('Email')}
                        inputAttributes={{
                            "data-testid": "email",
                            id: "email",
                            name: "email",
                            type:  "email",
                            placeholder: "*****@email.com",
                            ref: emailRegister,
                        }}
                        errorMessage={errors.email ? errors.email.message : null}
                    />
                </div>

                <div className="col-12 mt-2">
                    <CactusLabelInput
                        labelTitle={t('Password')}
                        inputAttributes={{
                            "data-testid": "password",
                            id: "password",
                            name: "password",
                            type:  "password",
                            placeholder: "*****",
                            autoComplete: "new-password",
                            ref: passwordRegister,
                        }}
                        errorMessage={errors.password ? errors.password.message : null}
                    />
                </div>

                <div className="col-12 mt-2">
                    <CactusLabelInput
                        labelTitle={t('Repeat Password')}
                        inputAttributes={{
                            "data-testid": "passwordRepeat",
                            id: "passwordRepeat",
                            name: "passwordRepeat",
                            type:  "password",
                            placeholder: "*****",
                            autoComplete: "new-password",
                            ref: passwordRepeatRegister,
                        }}
                        errorMessage={errors.passwordRepeat ? errors.passwordRepeat.message : null}
                    />
                </div>
                
                {_renderPremiumPartnerField()}
                
                {_renderBtnSubmit()}

            </div>
            
        </form>
    );

};

export default React.memo(RegisterFormPremium);
