import BaseService from "../../BaseService";
import HierarchyResponse from "./HierarchyResponse";
import { AxiosResponse } from "axios";

export default class HierarchyService extends BaseService {

    fetch(): Promise<AxiosResponse<HierarchyResponse>> {
        return this.axios.get(`/hierarchy`, this.getCancelTokenStructure());
    }

}
