import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const PlaylistMediaItemSkeleton = () => {
    return (
        <SkeletonTheme color={process.env.REACT_APP_SKELETON_COLOR} highlightColor={process.env.REACT_APP_SKELETON_HIHGLIGHTS_COLOR}>
            <div className="row mb-2">
                <div className="col-3">
                    <Skeleton height={40} width={55} />
                </div>
                <div className="col-9">
                    <div>
                        <Skeleton width={175} />
                    </div>
                    <div>
                        <Skeleton width={90} />
                    </div>
                </div>
            </div>
            <Skeleton width={240} height={3} />
        </SkeletonTheme>
    )
}
