import React from "react";
import { useTranslation } from 'react-i18next';

import { getFullName } from "../models/User";
import { OrganizationChartData } from "../models/Hierarchy";

export interface OrganizationChartTemplate {
    nodeData: any;
}

const OrganizationChartTemplate = (props: OrganizationChartTemplate) => {

    const [t] = useTranslation();
    const organizationChart: OrganizationChartData = props.nodeData;

    const _getTranslatedRol = (): string => {
        return organizationChart.data.rol?.map((rol: string) => t(`rol_${rol}`)).join(",") ?? "";
    }

    return (
        <div>
            <div className="oc-heading">
                {getFullName(organizationChart.data)}
            </div>
            <div className="oc-content">

                <div className="d-flex justify-content-around">

                    <div className="flex-1">
                        <strong>Rol:</strong>
                    </div>

                    <div className="flex-1">
                        {_getTranslatedRol()}
                    </div>

                </div>
            </div>
            <i className="oc-edge verticalEdge bottomEdge oci " />
        </div>
    );

}

export default React.memo(OrganizationChartTemplate);
