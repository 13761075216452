import * as actionTypes from '../actions/types';


export interface HeaderSubmenuReducerState {
    readonly status: boolean;
}

const INITIAL_STATE: HeaderSubmenuReducerState = {
    status: false
}

export default (state: any = INITIAL_STATE, action: any) => {
    switch(action.type) {

        case actionTypes.HEADER_SUBMENU:
            return {
                ...state,
                status: action.payload ,
            }

        default:
            return state;
        
    }
}
