import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export const NavHeaderSkeleton = () => {
    return (
        <SkeletonTheme color={process.env.REACT_APP_SKELETON_XS_COLOR} highlightColor={process.env.REACT_APP_SKELETON_XS_HIHGLIGHTS_COLOR}>
            <div>
                <Skeleton className='col-3' />
            </div>
            <div>
                <Skeleton className='col-5' />
            </div>
        </SkeletonTheme>
    )
}
