import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import Config from "../../../models/Config";

export default class ConfigService extends BaseService {

    fetch(): Promise<AxiosResponse<Array<Config>>> {
        return this.axios.get(`/configs`);
    }

}
