import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavHeaderSkeleton } from './NavHeaderSkeleton';

import { useHistory } from 'react-router-dom';
import * as actionCreator from "../../actions";
import { IconCactusLogoHorizontal } from '../../assets/icons/IconCactusLogoHorizontal';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';

interface NavHeaderProps {};

const NavHeader: React.FC<NavHeaderProps> = (props: NavHeaderProps) => {

    const [t] = useTranslation();


    let history = useHistory()
    const dispatch = useDispatch();

    const { status: showSubmenu } = useSelector( (store: any) => store.headerSubmenuReducer )

    const { user, isLoading } = useSelector( (store: any) => store.authReducer )

    const _renderSubmenu = () => {
        return (
            <div className="navbar__user__submenu"
                onMouseLeave={ () => dispatch( actionCreator.headerSubmenuChangeValue( false ) ) }
            >
                <ul>
                    <li onClick={ () => {
                        history.push('/profile')
                    } }>
                        {t('Profile')}
                    </li>
                    <li onClick={ () => dispatch( actionCreator.authLogout() ) }>
                        {t('Logout')}
                    </li>
                </ul>
            </div>
        )
    }

    const _renderUserInfo = () => {
        if (isLoading) {
            return (
                <NavHeaderSkeleton />
            )
        } 
        return (
            <>
                <div><strong>{ user?.name }</strong></div>
                <div className="navbar__user__email">{ user?.email  }</div>
            </>
        )
    }

    return(
        <nav className='navbar'
            onMouseLeave={ () => dispatch( actionCreator.headerSubmenuChangeValue( false ) )}
        >
            <div className='navbar__left'></div>
            <div className='navbar__logo'>
                <Link to='/dashboard'>
                    <IconCactusLogoHorizontal 
                        class='fill-gray-light'
                    />
                </Link>
            </div>
            <div className='navbar__user'>
                <div 
                    className='pointer' 
                    onClick={ () => dispatch( actionCreator.headerSubmenuChangeValue( !showSubmenu ) ) } 
                >
                    { _renderUserInfo() }
                {
                    showSubmenu && _renderSubmenu()
                }
                </div>
                <div className='navbar__user__lang_switch'>
                    <LanguageSwitch />
                </div>
            </div>
        </nav>
    );


};


export default React.memo(NavHeader);
