import BaseService from "../../BaseService";
import MediaResponse from "./MediaResponse";
import { AxiosResponse } from "axios";
import Media, { MediaFilter } from "../../../models/Media";

export default class MediaService extends BaseService {

    fetch(page: number = 1, search: string, filter: string = MediaFilter.all): Promise<AxiosResponse<MediaResponse>> {
        let uri: string = `/medias?page=${page}&filter=${filter}`;

        if(search !== "") uri += `&q=${search}`;

        return this.axios.get(uri, this.getCancelTokenStructure());
    }

    show(id: string): Promise<AxiosResponse<Media>> {
        return this.axios.get(`/medias/${id}`);
    }

    store(id: string, formData: FormData): Promise<AxiosResponse<Media>> {
        return this.axios.post(`/medias/${id}`, formData);
    }

    update(id: string, data: any): Promise<AxiosResponse<Media>> {
        return this.axios.put(`/medias/${id}`, {...data});
    }

    delete(id: string): Promise<AxiosResponse<null>> {
        return this.axios.delete(`/medias/${id}`);
    }

    userMedia(): Promise<AxiosResponse<MediaResponse>> {
        return this.axios.get(`/medias/user`);
    }

}
