import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FilterButton,
} from "../../components";

interface DashboardLogItemProps {
    name: string;
    email: string;
    date: string;
    type: string;
};

const DashboardLogItem: React.FC<DashboardLogItemProps> = (props: DashboardLogItemProps) => {

    const [t] = useTranslation();

    const textStyle = {
        fontSize: '.9rem',
    }

    return(
        <div className="card p-3">
            <div className="row">
                <div className="col-sm-3 d-flex align-items-center" style={textStyle}>
                    {props.name}
                </div>
                <div className="col-sm-3 d-flex align-items-center" style={textStyle}>
                    {props.email}
                </div>
                <div className="col-sm-3 d-flex align-items-center" style={textStyle}>
                    {props.date}
                </div>
                <div className="col-sm-3 d-flex align-items-center">
                    <FilterButton className={`btn btn-cactus btn-cactus--info text-white`}>
                        {props.type}
                    </FilterButton>
                </div>
            </div>
        </div>
    );

};

export default React.memo(DashboardLogItem);
