import React from 'react';
import { IconLoader } from '../../assets/icons/IconLoader';
import { IconRefresh } from '../../assets/icons/IconRefresh';

interface DashboardTitleProps {
    title: string;
    isLoading?: boolean;
    withRefreshIcon?: boolean;
    refreshIconCallBack?: () => void;
};

const styleIcon = {
    width: '20px',
    marginLeft: '10px'
} 
const styleRefreshIcon = {
    top: '-2px',
    width: '15px',
    marginLeft: '10px',
} 

const DashboardTitle: React.FC<DashboardTitleProps> = (props: DashboardTitleProps) => {

    return(
        <> 
            <div className="d-flex my-2">
                <h5 className="mb-0">{ props.title }</h5>
                {props.isLoading && <div style={styleIcon}><IconLoader class='stroke-gray-light' /></div> }
                {props.withRefreshIcon && <div className="position-relative" style={styleRefreshIcon} onClick={() => props.refreshIconCallBack?.()}>
                    <IconRefresh 
                        class="pointer"
                        fill='#969696'
                    />
                </div>}
            </div>
        </>
    );

};

export default React.memo(DashboardTitle);
