import BaseService from "../../BaseService";
import { AxiosResponse } from "axios";
import Schedule from "../../../models/Schedule";

export default class ScheduleService extends BaseService {

    fetch(): Promise<AxiosResponse<Schedule>> {
        return this.axios.get(`/schedules`);
    }

}
