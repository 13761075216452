import { AxiosResponse } from "axios";
import Schedule from "../models/Schedule";
import ScheduleService from "../services/use_case/schedule/ScheduleService";
import ActionType from "./Action";
import * as actionTypes from './types';

/*
 * SCHEDULE
 */
export const scheduleFetch = () => {

    return async (dispatch: any, getState: any) => {
        
        dispatch( scheduleChangeValue('isLoading', true) )
        
        try {
            let response: AxiosResponse<Schedule> = await new ScheduleService().fetch();
            let scheduleResponse: Schedule = response.data;
            dispatch( scheduleFetchResponse(scheduleResponse) );
            dispatch( scheduleChangeValue('isLoading', false) );
        }
        catch(error) {
            dispatch( scheduleChangeValue('isLoading', false) );
        }

    };

}

export const scheduleFetchResponse = (payload: Schedule): ActionType<Schedule> => {
    return {
        type: actionTypes.SCHEDULE_FETCH,
        payload,
    }
}

export const scheduleChangeValue = (key: string, value: any): ActionType<any> => {
    return {
        type: actionTypes.SCHEDULE_CHANGE_VALUE,
        payload: {
            key, 
            value
        },
    }
}
