import React from 'react';
import { Link } from 'react-router-dom';
import Playlist from '../../models/Playlist';

interface PlaylistItemProps {
    playlist: Playlist;
};

const PlaylistItem: React.FC<PlaylistItemProps> = (props: PlaylistItemProps) => {

    return(
        <div className="card mb-3">
            <div className="card-body playlist-item">
                <Link to={`playlists/${props.playlist.id}`} className="playlist-item__name" >
                    <i className="iconsminds-check"></i>
                    <span className="ml-2">{ props.playlist.name }</span>
                </Link>
                <div className="playlist-item__options">
                    <i className="iconsminds-gear pointer"></i>
                </div>
            </div>
        </div>
    );

};

export default React.memo(PlaylistItem);
